import React, { useEffect, useState, useRef } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography, Button, FormControl, Paper, Breadcrumbs, Link, Snackbar, Alert, Modal, CircularProgress, IconButton } from '@mui/material/'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useNavigate, useParams } from 'react-router-dom'
import { groupBy, isNull } from "lodash";
import Moment from 'moment';
import SignaturePad from "react-signature-canvas";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function Induction() {
    const navigate = useNavigate();
    const params = useParams();
    const [inductions, setInductions] = useState([]);
    const [user, setUser] = useState([]);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [incorrectQuestions, setIncorrectQuestions] = useState([]);
    const [videoWatched, setVideoWatched] = useState(false);
    const [submitCount, setSubmitCount] = useState(0);
    const sigCanvas = useRef({});

    /* a function that uses the canvas ref to clear the canvas 
       via a method given by react-signature-canvas */
    const clear = () => sigCanvas.current.clear();

    const handleClose4 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen4(false);
    };

    const getUserInfo = async () =>{
        try{
            const response = await fetch(
                `${localStorage.getItem("url")}/api/client_user/${localStorage.getItem("id")}`
            );
            const jsonData = await response.json();
            setUser(jsonData);
            console.log(jsonData)
        } catch (err){
            console.log(err.message);
        }
    }; 

    const getInduction = async () =>{
        try{
            const response = await fetch(
                `${localStorage.getItem("url")}/api/client_user_induction/${params.induction}`
            );
            const jsonData = await response.json();
            const questions = [];
            const answers = [];
            for(let i = 0; i < jsonData.length; i++){
                console.log("hello")
                console.log(jsonData[i].correct_answer)
                console.log(jsonData[i].question_text)
                answers.push(jsonData[i].correct_answer)
                questions.push(jsonData[i].question_text)
            }
            const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);
            // Shuffle the answers while keeping the questions in order
            const updatedInductions = jsonData.map(induction => ({
                ...induction,
                answer_text: shuffleArray(induction.answer_text.split(',')).join(',')
            }));
            setInductions(updatedInductions);
            setAnswers(answers);
            setQuestions(questions);
        } catch (err){
            console.log(err.message);
        }
    };

    const handleSubmit = () => {
        // Increase the submit count every time the button is pressed
        const newSubmitCount = submitCount + 1;
        setSubmitCount(newSubmitCount);

        const correct_answers_length = inductions.length;
        const correct_answers_array = [];
        let incorrect = [];

        inductions.forEach((induction) => {
            const answerElements = document.getElementsByName(induction.question_id);
            let correctFound = false;
            for(let i = 0; i < answerElements.length; i++){
                if(answerElements[i].checked && answerElements[i].value === induction.correct_answer){
                    correct_answers_array.push(answerElements[i].value);
                    correctFound = true;
                    break;
                }
            }
            if(!correctFound) {
                incorrect.push(induction.question_id);
            }
        });

        console.log("Correct count:", correct_answers_array.length);
        console.log("Expected:", correct_answers_length);
        console.log("Submit count:", newSubmitCount);

        if(incorrect.length === 0 && correct_answers_array.length === correct_answers_length){
            completeInduction(newSubmitCount);
        } else {
            setIncorrectQuestions(incorrect);
            setOpen4(true);
        }
    };

    const completeInduction = async () => {
        console.log(questions)
        console.log(answers)
                const user_id = user[0].id
                const induction = inductions[0].induction_id   
                const current = new Date();
                const datetime = Moment(current).format('YYYY-MM-DD-H-mm-s');
                console.log(Moment(current).format('YYYY-MM-DD-H:mm:ss'))
                const sign = sigCanvas.current.getTrimmedCanvas().toDataURL();
                const q = questions;
                const a = answers;
                const org = localStorage.getItem("org_id")
                const body = { user_id,induction,datetime, org, sign, q, a};
                console.log(body)
                console.log(Moment(current).format('YYYY-MM-DD'))

                const response =  fetch(
                    `${localStorage.getItem("url")}/api/completeInductionSingle`,
                    {
                      method: "POST",
                      headers: { "content-type": "application/json" },
                      body: JSON.stringify(body),
                    }
                  )
                    setOpen3(true);


      
            
        
      }

    const finishInduction = () => {
        navigate("/")
    };

    const handleVideoProgress = (progress) => {
        // If video is 90% watched, allow questions to be displayed
        if (progress.played >= 0.9) {
            setVideoWatched(true);
        }
    };

    useEffect(() => {
        getInduction();
        getUserInfo();
    }, []);
    
    const styleModal = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxSizing: 'border-box',
        width: {
            xs: 300,
            sm: 450,
        },
        bgcolor: 'white',
        borderRadius: '4px',
        boxShadow: 24,
        p: 4,
    };

    if(inductions.length){
        return (
            <Box 
                sx={{ 
                    width: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems:'center', 
                    boxSizing: 'border-box'
                }}>
                <Box sx={{width: '100%', backgroundColor: '#66BC44', display: 'flex', justifyContent: 'center'}}>
                    <Box sx={{  
                        width: {
                            xs: 300,
                            sm: 500,
                            md: 800,
                            lg: 1000,
                            xl: 1200,
                        },  
                        height: '150px', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-end'
                    }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="rgb(245,245,245)" href="/user">
                                Dashboard
                            </Link>
                        </Breadcrumbs>
                        <Typography variant='h4' sx={{ mb: 3, color: 'white', fontSize: {
                            xs: '24px',
                            sm: '26px',
                            md: '30px',
                            lg: '32px',
                            xl: '34px'
                        }}}>
                            {inductions[0].induction_title}
                        </Typography>
                    </Box>
                </Box>
                <Box 
                    sx={{ 
                        width: {
                            xs: 300,
                            sm: 500,
                            md: 800,
                            lg: 1000,
                            xl: 1200,
                        }, 
                        mb: 10,
                        mt: 2
                    }}>
                    <Paper sx={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'column' , p: 4}}>
                        <Typography variant='h6' sx={{mb: 2 }}>Instructions</Typography>
                        <Typography sx={{mb: 3}}>{inductions[0].induction_description}</Typography>
                        {inductions[0].video_path === null
                        ? <Box></Box>
                        : <Box sx={{ position: 'relative', pt: '56.25%', mb: 2}}>
                            <ReactPlayer
                                style={{ position: 'absolute', top: '0', left: '0' }}
                                className='react-player'
                                url={inductions[0].video_path}
                                width='100%'
                                height='100%'
                                controls={true}
                                onProgress={handleVideoProgress}
                            />
                        </Box>
                        }
                        {videoWatched ? (
                            <>
                                <Typography variant='h6' sx={{mb: 2 }}>Questions</Typography>
                                {inductions.map((induction) => (
                                    <FormControl sx={{mb: 3}} key={induction.question_id}>
                                        <FormLabel 
                                            sx={{ 
                                                color: incorrectQuestions.includes(induction.question_id) ? 'red' : 'black',
                                                fontWeight: incorrectQuestions.includes(induction.question_id) ? 'bold' : 'normal'
                                            }}>
                                            {induction.question_text}
                                        </FormLabel>
                                        <RadioGroup name={`${induction.question_id}`}>
                                            {induction.answer_text.split(',').map((answer, index) => (
                                                <FormControlLabel key={index} value={answer} control={<Radio />} label={answer}/>
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                ))}
                                <Box sx={{ backgroundColor: '#FFFF' }}>
                                    <SignaturePad
                                        ref={sigCanvas}
                                        canvasProps={{ className: "signatureCanvas" }}
                                    />
                                </Box>
                                <Box>
                                    <IconButton color="primary" onClick={clear} sx={{pb: 4}}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Button onClick={handleSubmit} variant='contained' >Submit</Button>
                                </Box>
                            </>
                        ) : (
                            <Typography sx={{ mt: 2, fontStyle: 'italic', color: 'gray' }}>
                                Please watch the video before answering the questions.
                            </Typography>
                        )}
                    </Paper>
                    <Button href={`/`} variant='contained' sx={{mt: 3}}>back</Button>
                    <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
                        <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
                            One or more answers are incorrect.
                        </Alert>
                    </Snackbar>
                </Box>
                <Modal
                    open={open3}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={styleModal}>
                        <Typography variant='h5' mb={1}>Induction Completed</Typography>
                        <Typography variant='body1' color={'GrayText'} mb={3}>
                            All questions have been answered correctly!
                        </Typography>
                        <Button onClick={finishInduction} sx={{width: '100%'}} variant='contained'>
                            Finish Induction
                        </Button>
                    </Box>
                </Modal>
            </Box>
        )
    } else {
        return(
            <Box sx={{width: '100%', backgroundColor: '#66BC44', display: 'flex', justifyContent: 'center'}}>
                <Box sx={{  
                    width: {
                        xs: 300,
                        sm: 500,
                        md: 800,
                        lg: 1000,
                        xl: 1200,
                    },  
                    height: '150px', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'flex-end'
                }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="rgb(245,245,245)" href="/user">
                            Dashboard
                        </Link>
                    </Breadcrumbs>
                    <CircularProgress sx={{color: '#FFFFFF', mb: 3}}/>
                </Box>
            </Box>
        )
    }
}

export default Induction;
