import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography, Button, Modal, Select, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Autocomplete, Breadcrumbs, IconButton, Grid} from '@mui/material/'
import AddIcon from '@mui/icons-material/Add';
import Link from '@mui/material/Link';

function AddVideo() {
  const [category, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);

  const styleModalVideo = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
  };

  const styleModal = {
    position: 'absolute',
    borderRadius: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    height: '70vh',
    padding: 6,
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
    boxSizing: 'border-box'
  };

  const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    {
      title: 'The Lord of the Rings: The Return of the King',
      year: 2003,
    },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    {
      title: 'The Lord of the Rings: The Fellowship of the Ring',
      year: 2001,
    },
    {
      title: 'Star Wars: Episode V - The Empire Strikes Back',
      year: 1980,
    },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    {
      title: 'The Lord of the Rings: The Two Towers',
      year: 2002,
    },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    {
      title: 'Star Wars: Episode IV - A New Hope',
      year: 1977,
    },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    {
      title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
      year: 1964,
    }
  ]

  return (
    <Box sx={{ width: '85%', display: 'flex', justifyContent: 'center', marginLeft: '250px', boxSizing: 'border-box'}}>
        <Box sx={{ width: '1200px'}}>
            <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                <Link underline="hover" color="inherit" href="/bayley">
                    Dashboard
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href="/bayley/onboarding/"
                >
                    Onboarding
                </Link>
            </Breadcrumbs>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2, }}>
                <Typography  variant="h4" sx={{ fontWeight: '600'}}>Onboarding Video</Typography>
                <Button variant="contained">
                    Create
                </Button>
            </Box>
            <Paper sx={{ p: 4, boxSizing: 'border-box', mb: 2}}>
                <Grid container spacing={2} sx={{ pb: 4}}>
                    <Grid xs={6}>
                        <Box>
                            <Typography>Video Title</Typography>
                            <Autocomplete
                                freeSolo
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                options={top100Films.map((option) => option.title)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='Video Title'
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={6}>
                        <Box>
                            <Typography>Video Description</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Breif description'
                            />
                        </Box>
                    </Grid>
                    <Grid xs={3}>
                        <Box>
                            <Typography>Role</Typography>
                            <Autocomplete
                                freeSolo
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                options={top100Films.map((option) => option.title)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='Video Title'
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={7}>
                        <Box>
                            <Typography>Video URL</Typography>
                            <TextField 
                                sx={{ width: '100%'}} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Video URL'
                            />
                        </Box>
                    </Grid>
                    <Grid xs={2}>
                        <Box>
                            <Typography>Video Thumbnail</Typography>
                            <Button sx={{width: '100%'}} variant='contained'>add file</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ p: 4, boxSizing: 'border-box', mb: 10}}>
                    <Box sx={{display: 'flex'}}>
                        <Box width='50%'>
                            <Typography>Questions</Typography>
                        </Box>
                        <Box width='50%'>
                            <Typography sx={{ml: 5}}>Answers</Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex'}}>
                        <Box width='50%' sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <TextField 
                                sx={{ width: '12%'}} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Order'
                                defaultValue='1'
                            /> 
                            <TextField 
                                sx={{ width: '85%'}} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Question Text'
                                multiline
                            /> 
                        </Box>
                        <Box width='50%'>
                            <Box width='100%' sx={{display: 'flex'}}>
                                
                                <Checkbox defaultChecked />
                                <TextField 
                                    sx={{ width: '100%'}} 
                                    size='small' 
                                    id="outlined" 
                                    variant="outlined" 
                                    placeholder='Answer Text'
                                />
                            </Box>
                            <IconButton sx={{ml: 5}}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Button variant='contained'>add question</Button>
            </Paper>
        </Box>
    </Box>
  )
}

export default AddVideo