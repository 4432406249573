import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid2, Card, CardActionArea, CardContent, CircularProgress, IconButton, Button,createTheme, ThemeProvider, Modal } from '@mui/material'
import Thumbnail from '../../../Assets/thumbnail.jpg'
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../Assets/logoiconwhite.png'
import Moment from 'moment';

function UserDashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [inductions, setInductions] = useState([]);
  const [complete, setComplete] = useState([]);
  const [video, setVideos] = useState([]);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);

  const handleClose3 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen3(false);
  };

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#66BC44',
        contrastText: 'rgba(255,255,255,0.87)',
      },
      secondary: {
        main: '#f50057',
      },
    },
  });

  const styleModal = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
    width: {
        xs: 300,
        sm: 450,
      },
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const getUsers = async () =>{
    try{
      const response = await fetch(
          `${localStorage.getItem("url")}/api/group_users_dashboard/${localStorage.getItem("group_id")}/${localStorage.getItem("org_id")}`
      );
      const jsonData = await response.json();
      console.log(jsonData)
      setUser(jsonData);
      
     
      
  } catch (err){
      console.log(err.message);
  }
  
}; 

const getInductions = async () =>{
  try{
    const response = await fetch(
        `${localStorage.getItem("url")}/api/user_inductions/${localStorage.getItem("category_id")}`
    );
    const jsonData = await response.json();
 
    setInductions(jsonData);
    console.log(jsonData)

   
    
} catch (err){
    console.log(err.message);
}
}; 

const navInduction = async (id) => {
  console.log(id);
  navigate(`${id}`)
 


}

const handleFinish = async (req, res) => {
       
               
            
    const current = new Date();
   
    const datetime = Moment(current).format('YYYY-MM-DD hh:mm:ss');
    const group_id = localStorage.getItem("group_id")
    const org = localStorage.getItem("org_id")

    const body = { datetime, org, group_id };
  
    console.log(Moment(current).format('YYYY-MM-DD'))

    const response =  fetch(
        `${localStorage.getItem("url")}/api/updateInductionGroup`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      )
      localStorage.removeItem("induction_group_id")
      localStorage.removeItem("org_id");
      localStorage.removeItem("group_id");
navigate("/")
}

const getInductionsComplete = async () =>{
  try{
    const response = await fetch(
        `${localStorage.getItem("url")}/api/group_inductions_complete/${localStorage.getItem("group_id")}`
    );
    const jsonData = await response.json();
 
    setComplete(jsonData);
    console.log(jsonData)

   
    
} catch (err){
    console.log(err.message);
}
}; 

const finishInduction = () => {
    navigate(`/group/${localStorage.getItem("group_id")}/${localStorage.getItem("org_id")}`)
   
   
  
}

const getVideos = async () =>{
  try{
    const response = await fetch(
        `${localStorage.getItem("url")}/api/videos/${localStorage.getItem("org_id")}`
    );
    const jsonData = await response.json();
 
    setVideos(jsonData);
    console.log(jsonData)

   
    
} catch (err){
    console.log(err.message);
}
}; 



  useEffect(() => {
    getUsers();
    getInductions();
    getInductionsComplete();
    getVideos();
    
  
   }, []);


  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
        <Box sx={{ width: '100%', justifyContent: 'center', display: 'flex', backgroundColor: '#66BC44', paddingTop: 3}}>
              <Box sx={{             
                width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            },  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <IconButton onClick={finishInduction}>
                  <img src={Logo} width="50px" height="50px" style={{cursor: 'pointer', objectFit: 'contain'}}/>
                </IconButton>
              </Box>
          </Box>
      <Box sx={{width: '100%', backgroundColor: '#66BC44', display: 'flex', justifyContent: 'center'}}>
        <Box sx={{             
          width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            }
            , height: {
              xs: 90,
              xl: 150,
            }, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
        {inductions.length 
            ?
              <Typography variant='h4' sx={{ mb: 3, color: 'white', fontSize: {
                xs: '24px',
                sm: '26px',
                md: '30px',
                lg: '32px',
                xl: '34px'
              }}}>{inductions[0].category_name} Induction</Typography>
            :
            <CircularProgress sx={{color: '#FFFFFF', mb: 3}}/>
        }
        </Box>
      </Box>
      <Box sx={{             
        width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            } , pt: 3, pb: 10}}>
          <Grid2 container spacing={2} sx={{pb: 5}}>
            
          {inductions.length && video.length?
          inductions.map((i) => 
            
            {
              const completed = complete.filter(o => o.induction_id === i.id)
              const thumbnail = video.find(o => o.video_id === i.video_id)
              const groupuser = user.filter(o => o.induction_id === i.id)
              
              if (completed?.length === groupuser?.length ) {
                return(
                  <Grid2 size={{ sx: 12, sm: 6, md: 4 }} lg={4} key={i.id}>
                      <Card>
                          <CardActionArea >
                            <Box sx={{ width: '100%',                            
                            height: {
                              xs: 250,
                              sm: 200,
                              md: 300,
                              lg: 300
                            },  display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <img src={i.thumbnail_path} width='100%' height='100%' style={{ objectFit: 'cover', objectPosition: 'center' }}/>
                              
                            </Box>
                              <CardContent sx={{height: '100px'}}>
                              <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center'}}>
                                  {i.induction_title}
                              </Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1}}>
                                <CircleIcon sx={{ width: '10px', color: 'green', pr: 1}}/>
                                <Typography>Complete</Typography>
                              </Box>
                              </CardContent>
                          </CardActionArea>
                      </Card>
                    </Grid2>
                  )
              }
              else {
                return(
                  <Grid2 size={{ sx: 12, sm: 6, md: 4 }} key={i.id} onClick={() => {navInduction(i.id)}}>
                      <Card>
                          <CardActionArea >
                            <Box sx={{ width: '100%', 
                            height: {
                              xs: 250,
                              sm: 200,
                              md: 300,
                              lg: 300
                            }, 
                            display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                              <img src={i.thumbnail_path} width='100%' height='100%' style={{ objectFit: 'cover', objectPosition: 'center' }}/>
                            </Box>
                              <CardContent sx={{height: '100px'}}>
                              <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center'}}>
                                  {i.induction_title}
                              </Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1}}>
                                <CircleIcon sx={{ width: '10px', color: 'red', pr: 1}}/>
                                <Typography>Need to complete</Typography>
                              </Box>
                              </CardContent>
                          </CardActionArea>
                      </Card>
                    </Grid2>
                  )
              }
  
              })
              :
              <CircularProgress sx={{color: '#303030'}}/>
              }
        
          
              
             
          </Grid2>
          {complete.length === user.length
            ?
            <Box>
              <Button onClick={handleOpen3} variant='contained' >Finish Induction Group</Button>
            </Box>
            :
            <Box>
              </Box>
          }

        </Box>
        <Modal
            open={open3}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
              <Typography variant='h5' mb={1}>Induction Completed</Typography>
              <Typography variant='body1' color={'GrayText'} mb={3}>All questions have been answered correctly!</Typography>
              <Button onClick={handleFinish} sx={{width: '100%'}} variant='contained'>Finish Induction</Button>
          </Box>
        </Modal>
    </Box>
    </ThemeProvider>
  )
}

export default UserDashboard