import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import {
  Box,
  Typography,
  CircularProgress,
  createTheme,
  ThemeProvider,
  Button,
  Modal,
  Snackbar,
  Alert,
  TextField,
  FormControl,
  Paper,
  Breadcrumbs,
  Autocomplete,
  IconButton,
  Backdrop,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Moment from 'moment';
import { groupBy } from "lodash";
import { useNavigate } from 'react-router';
import SignaturePad from "react-signature-canvas";
import './group.css';

// Helper function to shuffle an array using the Fisher-Yates algorithm
const shuffleArray = (array) => {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

function Induction1() {
  const sigCanvas = useRef({});
  const navigate = useNavigate();
  const [groupTotal, setGroupTotal] = useState("");
  const [inductions, setInductions] = useState([]);
  const [usersWatching, setUsersWatching] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [inductionGroupID, setInductionGroupID] = useState("");
  const [inductionID, setInductionID] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(true);
  const [incorrectQuestions, setIncorrectQuestions] = useState({}); // Track incorrect questions

  const [openErrorUser, setOpenErrorUser] = useState(false);
  const [openErrorSign, setOpenErrorSign] = useState(false);
  const [openIncorrect, setOpenIncorrect] = useState(false);
  const [openNoMore, setOpenNoMore] = useState(false);
  const [openFirst, setOpenFirst] = useState(false);
  const [openCompletedModal, setOpenCompletedModal] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: { main: '#66BC44', contrastText: 'rgba(255,255,255,0.87)' },
      secondary: { main: '#f50057' }
    }
  });

  const sleep = ms => new Promise(r => setTimeout(r, ms));
  const clearSignature = () => sigCanvas.current.clear();

  const getInductionUsers = async () => {
    try {
      const inductionsResponse = await fetch(
        `${localStorage.getItem("url")}/api/inductions/${localStorage.getItem("org_id")}/${localStorage.getItem("category_id")}`
      );
      const inductionsData = await inductionsResponse.json();
      if (!inductionsData?.length) return;
      const grouped = groupBy(inductionsData, 'induction_id');
      const inductionIds = Object.keys(grouped);
      let selectedInductionId = null, groupUsers = [];
      for (let id of inductionIds) {
        const usersResponse = await fetch(
          `${localStorage.getItem("url")}/api/group_users/${localStorage.getItem("group_id")}/${localStorage.getItem("org_id")}/${id}`
        );
        const usersData = await usersResponse.json();
        if (usersData?.length) {
          selectedInductionId = id;
          groupUsers = usersData;
          break;
        }
      }
      if (!selectedInductionId) {
        setFinished(true);
        setOpenCompletedModal(true);
      } else {
        localStorage.setItem("induction_group_id", groupUsers[0].category_id);
        setInductionGroupID(groupUsers[0].category_id);
        setInductionID(groupUsers[0].induction_id);
        const processedInductions = grouped[selectedInductionId].map(induction => ({
          ...induction,
          shuffledAnswers: shuffleArray(induction.answer_text.split(','))
        }));
        setInductions(processedInductions);
        setUsersWatching(groupUsers);
        const newQuestions = [], newAnswers = [];
        grouped[selectedInductionId].forEach(item => {
          if (!newQuestions.includes(item.question_text)) {
            newQuestions.push(item.question_text);
            newAnswers.push(item.correct_answer);
          }
        });
        setQuestions(newQuestions);
        setAnswers(newAnswers);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      await sleep(1000);
      setLoading(false);
    }
  };

  const getCategoryCount = async () => {
    try {
      const response = await fetch(
        `${localStorage.getItem("url")}/api/category/${localStorage.getItem("org_id")}/${localStorage.getItem("induction_group_id")}`
      );
      const jsonData = await response.json();
      setCategoryCount(jsonData.length);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getGroupTotal = async () => {
    try {
      const response = await fetch(
        `${localStorage.getItem("url")}/api/groupTotal/${localStorage.getItem("org_id")}/${localStorage.getItem("group_id")}`
      );
      const jsonData = await response.json();
      setGroupTotal(jsonData[0].count);
      await sleep(2000);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSubmit = () => {
    const isSignatureEmpty = sigCanvas.current.isEmpty();
    const newIncorrect = {};
    let correctAnswersCount = 0;

    if (currentUser === "") {
      setOpenErrorUser(true);
      return;
    } else if (isSignatureEmpty) {
      setOpenErrorSign(true);
      return;
    }

    // Check each question's answers
    inductions.forEach(induction => {
      const answersEl = document.getElementsByName(induction.question_id);
      let answeredCorrectly = false;
      for (let i = 0; i < answersEl.length; i++) {
        if (answersEl[i].checked) {
          if (answersEl[i].value === induction.correct_answer) {
            answeredCorrectly = true;
          }
          break;
        }
      }
      newIncorrect[induction.question_id] = !answeredCorrectly;
      if (answeredCorrectly) {
        correctAnswersCount++;
      }
    });

    setIncorrectQuestions(newIncorrect);
    if (correctAnswersCount === inductions.length) {
      setBackdropOpen(true);
      completeInduction(currentUser);
    } else {
      setOpenIncorrect(true);
    }
  };

  const completeInduction = async userName => {
    for (const user of usersWatching) {
      if (user.name === userName) {
        const body = {
          user_id: user.user_id,
          induction: inductions[0].induction_id,
          datetime: Moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
          org: localStorage.getItem("org_id"),
          group_id: localStorage.getItem("group_id"),
          sign: sigCanvas.current.getTrimmedCanvas().toDataURL(),
          q: questions,
          a: answers
        };
        await fetch(`${localStorage.getItem("url")}/api/completeInduction`, {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(body)
        });
        await sleep(2000);
        window.location.reload();
        break;
      }
    }
  };

  const handleFinish = async () => {
    const body = {
      datetime: Moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
      org: localStorage.getItem("org_id"),
      group_id: localStorage.getItem("group_id")
    };
    await fetch(`${localStorage.getItem("url")}/api/updateInductionGroup`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(body)
    });
    localStorage.removeItem("induction_group_id");
    localStorage.removeItem("org_id");
    localStorage.removeItem("group_id");
    navigate("/");
  };

  useEffect(() => {
    getInductionUsers();
    getCategoryCount();
    getGroupTotal();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  return inductions.length > 0 || finished ? (
    <ThemeProvider theme={theme}>
      <Box sx={{ pl: { xs: '0px', lg: '60px' }, pt: { xs: 6, lg: 0 }, width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: { xs: 300, sm: 500, md: 800, lg: 1000, xl: 1200 }, mb: 10 }}>
          <Snackbar open={openErrorUser} autoHideDuration={6000} onClose={() => setOpenErrorUser(false)}>
            <Alert severity="error" onClose={() => setOpenErrorUser(false)}>
              Please select the user answering the questions.
            </Alert>
          </Snackbar>
          <Snackbar open={openErrorSign} autoHideDuration={6000} onClose={() => setOpenErrorSign(false)}>
            <Alert severity="error" onClose={() => setOpenErrorSign(false)}>
              Please sign in the signature box.
            </Alert>
          </Snackbar>
          <Snackbar open={openIncorrect} autoHideDuration={6000} onClose={() => setOpenIncorrect(false)}>
            <Alert severity="error" onClose={() => setOpenIncorrect(false)}>
              One or more answers are incorrect.
            </Alert>
          </Snackbar>
          <Snackbar open={openNoMore} autoHideDuration={6000} onClose={() => setOpenNoMore(false)}>
            <Alert severity="error" onClose={() => setOpenNoMore(false)}>
              Not all users have been inducted.
            </Alert>
          </Snackbar>
          <Snackbar open={openFirst} autoHideDuration={6000} onClose={() => setOpenFirst(false)}>
            <Alert severity="error" onClose={() => setOpenFirst(false)}>
              This is the first induction of the group.
            </Alert>
          </Snackbar>
          <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={backdropOpen}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Breadcrumbs sx={{ mt: 5 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', mb: 2 }}>
            <Typography variant="h4" sx={{ fontWeight: '600' }}>Group Induction</Typography>
          </Box>
          <Paper sx={{ display: 'flex', flexDirection: 'column', p: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: '600' }}>
              {inductions.length ? inductions[0].induction_title : "Loading..."}
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography>User</Typography>
              <Autocomplete
                freeSolo
                size="small"
                sx={{ width: { xs: '100%', md: '60%', xl: '50%' }, mb: 3 }}
                disableClearable
                options={usersWatching.map(user => user.name)}
                onChange={(e, value) => setCurrentUser(value)}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Who is answering the questions?" InputProps={{ ...params.InputProps, type: 'search' }} />
                )}
              />
            </Box>
            {inductions.map(induction => (
              <FormControl key={induction.question_id} sx={{ mb: 3 }}>
                <FormLabel
                  sx={{
                    color: incorrectQuestions[induction.question_id] ? 'red' : 'black'
                  }}
                  id={`label-${induction.question_id}`}
                >
                  {induction.question_text}
                </FormLabel>
                <RadioGroup aria-labelledby={`label-${induction.question_id}`} name={induction.question_id}>
                  {induction.shuffledAnswers.map((answer, index) => (
                    <FormControlLabel key={index} value={answer} control={<Radio />} label={answer} />
                  ))}
                </RadioGroup>
              </FormControl>
            ))}
            <Typography>Signed by the employee:</Typography>
            <Box sx={{ backgroundColor: '#FFFF' }}>
              <SignaturePad ref={sigCanvas} canvasProps={{ className: "signatureCanvas" }} />
              <IconButton color="primary" onClick={clearSignature} sx={{ pb: 4 }}>
                <CloseRoundedIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button type="submit" onClick={handleSubmit} sx={{ mb: 2 }} variant="contained">Submit</Button>
            </Box>
          </Paper>
        </Box>
        <Modal open={openCompletedModal}>
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: 300, sm: 450 },
              bgcolor: 'white',
              borderRadius: '4px',
              boxShadow: 24,
              p: 4
            }}
          >
            <Typography variant="h5" mb={1}>Induction Completed</Typography>
            <Typography variant="body1" color="GrayText" mb={3}>All users have been inducted.</Typography>
            <Button type="submit" onClick={handleFinish} sx={{ width: '100%' }} variant="contained">Finish Induction</Button>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  ) : (
    <Box>
      <CircularProgress color="inherit" />
    </Box>
  );
}

export default Induction1;
