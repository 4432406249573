import logo from './logo.svg';
import './App.css';
import HomeV2 from './pages/infinite-portal/HomeV2';
import SideMenu from './pages/infinite-portal/SideMenu';
import Client from './pages/infinite-portal/Client';
import { redirect ,Route, Routes, useNavigate } from 'react-router-dom';
import Library from './pages/infinite-portal/Library';
import AddVideo from './pages/infinite-portal/AddVideo';
import EditVideo from './pages/infinite-portal/EditVideo';
import Onboarding from './pages/infinite-portal/Onboarding';
import Login from './pages/login/Login.js'
import LoginGroup from './pages/client-portal/group_inductions/group_login.js';
import Reset from './pages/login/Reset'
import { Fragment, useEffect } from 'react';
import ClientLibrary from './pages/client-portal/Library';
import ClientAddVideo from './pages/client-portal/AddVideo';
import ClientEditVideo from './pages/client-portal/EditVideo';
import ClientInductions from './pages/client-portal/Inductions';
import Dashboard from './pages/client-portal/Dashboard.js';
import User from './pages/client-portal/User.js';
import UserInduction from './pages/client-portal/UserInduction.js';
import Watch from './pages/client-portal/Watch';
import Indcution1 from './pages/client-portal/Induction1';
import Indcution2 from './pages/client-portal/Induction2';
import UserMenu from './pages/user-frontend/UserMenu';
import UserDashboard from './pages/user-frontend/UserDashboard';
import Induction from './pages/user-frontend/Induction';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import InductionV2 from './pages/client-portal/InductionV2';
import Category from './pages/client-portal/Category';
import Tracker from './pages/client-portal/Tracker';
import InductionGroups from './pages/client-portal/InductionGroups'
import InductionGroup from './pages/client-portal/InductionGroup'
//import InductionGroup2 from './pages/client-portal/group_inductions/group_induction_frontend1'
import InductionGroup2 from './pages/client-portal/group_inductions/group_dashboard.js'
import Signuture from './pages/client-portal/Signuture.js';
import Equipment from './pages/client-portal/Equipment.js';
import Allocation from './pages/client-portal/Allocation.js';
import AllocationUser from './pages/client-portal/AllocationUser.js';
import GroupInductionSingle from './pages/client-portal/group_inductions/GroupInductionSingle.js'
import GroupInductionMutiple from './pages/client-portal/group_inductions/GroupInductionMutiple.js'

function App() {
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#66BC44',
        contrastText: 'rgba(255,255,255,0.87)',
      },
      secondary: {
        main: '#f50057',
      },
    },
  });

  if (process.env.NODE_ENV === "production") {
    localStorage.setItem(
      "url",
      "https://upskill.herokuapp.com"
     
    );
  } else {
    localStorage.setItem("url", "http://localhost:3000");
  }
  const org = localStorage.getItem("org");
  const user = localStorage.getItem("user");
  const access_level = localStorage.getItem("access_level");



  if (localStorage.getItem("authenticated") == "true" && localStorage.getItem("access_level") == 1 ) {
 
      return(

        <div className='app_container'>
            <ThemeProvider theme={theme}>
            <SideMenu />
      
             <Routes>
  
  
             <Route path='/' element={<RedirectUser />}/>
                    <Route path='*' element={<RedirectUser />}/>
             <Route path='/' >
                  <Route index element={<UserDashboard />} />
                  <Route path='induction/:induction' element={<Induction />} />
            
                </Route>
              </Routes>
              </ThemeProvider>
          </div>
        )
  
    }else if(localStorage.getItem("authenticated") == "true" && localStorage.getItem("access_level") == 2){
      return(
        
        <div className='app_container'>
                <ThemeProvider theme={theme}>
                <SideMenu />
                  <Routes>
                    <Route path='/' element={<RedirectClient />}/>
                    <Route path='*' element={<RedirectClient />}/>
                  <Route path='/' >
                  
      
                      <Route index element={<Dashboard />} />
                      <Route path='watch/:category' element={<Indcution2 />} />
                      <Route exact path=':user/:induction' element={<UserInduction />} />
                      <Route exact path=':user' element={<User />} />
                      <Route path='/' element={<Dashboard />} />
                      <Route path='library' element={<ClientLibrary />} />
                      <Route path='onboarding/add' element={<ClientAddVideo />} />
                      <Route path='inductions' element={<ClientInductions />} />
                      <Route path='inductions/:induction' element={<InductionV2 />} />
                      <Route path='categories' element={<Category />} />
                      <Route path='tracker' element={<Tracker />} />
                      <Route path='equipment' element={<Equipment />} />
                      <Route path='equipment-allocation' element={<Allocation />} />
                      <Route path='equipment-allocation/:user' element={<AllocationUser />} />
                      <Route path='group' element={<InductionGroups />} />
                      <Route path='group/:id' element={<InductionGroup />} />
                      <Route path='group/:id/:induction_id' element={<InductionGroup2 />} />
                      <Route path='sign' element={<Signuture />} />
                      
                     
                      
                  </Route>
                    
                  </Routes>
                  </ThemeProvider>
              </div>
      
      )
    }else if(localStorage.getItem("authenticated") == "true" && localStorage.getItem("access_level") == 3){
      return (

        <div className='app_container'>
          <ThemeProvider theme={theme}>
          <SideMenu />
    
            <Routes>
              <Route path='/' element={<RedirectInfinite />}/>
              <Route path='/infinite' element={<SideMenu />} >
                <Route index element={<HomeV2 />} />
                <Route path=':user' element={<Client />} />
                <Route path='library' element={<Library />} />
                <Route path='onboarding/add' element={<AddVideo />} />
                <Route path='onboarding/edit' element={<EditVideo />} />
                <Route path='onboarding' element={<Onboarding />} />
              </Route>


              {/* <Route path={org} >
          
                <Route index element={<Users />} />
                <Route path='users/:user' element={<User />} />
                <Route path='users' element={<Users />} />
                <Route path='library' element={<ClientLibrary />} />
                <Route path='onboarding/add' element={<ClientAddVideo />} />
                <Route path='onboarding/edit' element={<ClientEditVideo />} />
                <Route path='inductions' element={<ClientInductions />} />
              
                <Route path='watch/video' element={<Indcution1 />} />
                <Route path='watch/video2' element={<Indcution2 />} />
                
              </Route> */}
              <Route path='/user' element={<UserMenu />} >
                <Route index element={<UserDashboard />} />
                <Route path='induction' element={<Induction />} />
              </Route>
            </Routes>
            </ThemeProvider>
        </div>
      );
    
  
  }else{


    return (
      <div>
         
    
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path=':token' element={<Reset />} />
              <Route path='/group' element={<LoginGroup />}/>
              <Route path='group/:id/:category_id/:induction_id' element={<GroupInductionSingle />} />
              <Route path='group/:id/:induction_id' element={<GroupInductionMutiple />} />
            </Routes>
        </div>
      );

   

    
      
  
}
  
}

export default App;

function RedirectClient() {
  const navigate = useNavigate();

  useEffect(() => {
      // 👇 Redirects to about page, note the `replace: true`
      navigate('/users', { replace: true });
  }, []);

}

function RedirectInfinite() {
  const navigate = useNavigate();

  useEffect(() => {
      // 👇 Redirects to about page, note the `replace: true`
      navigate('/infinite', { replace: true });
  }, []);

}

function RedirectLogin() {
  const navigate = useNavigate();

  useEffect(() => {
      // 👇 Redirects to about page, note the `replace: true`
      navigate('/', { replace: true });
  }, []);

}

function RedirectUser() {
  const navigate = useNavigate();

  useEffect(() => {
      // 👇 Redirects to about page, note the `replace: true`
      navigate('/', { replace: true });
  }, []);

}

