import React, { Fragment,useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography, Button, Modal, Select, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link, IconButton, Autocomplete, Snackbar, Alert, Fab } from '@mui/material/'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Thumbnail from '../../Assets/thumbnail.jpg'
import AddIcon from '@mui/icons-material/Add';
import MovieIcon from '@mui/icons-material/Movie';
import ImageIcon from '@mui/icons-material/Image';
import TitleIcon from '@mui/icons-material/Title';
import DescriptionIcon from '@mui/icons-material/Description';
import { GridActionsCellItem } from '@mui/x-data-grid';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Add, Close, DeleteOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router';

function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 2,
          boxSizing: 'border-box',
          pb: 0,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <GridToolbarQuickFilter sx={{ width: '100%'}} />
      </Box>
    );
  }
  
  
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

function Tracker() {
    const navigate = useNavigate();
  const [category, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [row, setRows] = useState([]);
  const [videos, setVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen3 = () => setOpen3(true);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);

      // Creating Induction Error Alert
      const [open4, setOpen4] = React.useState(false);
      const handleOpen4 = () => setOpen4(true);
      const handleClose4 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen4(false);
      };
            // Creating Induction Error Alert
            const [open5, setOpen5] = React.useState(false);
            const handleOpen5 = () => setOpen5(true);
            const handleClose5 = (event, reason) => {
              if (reason === 'clickaway') {
                return;
              }
          
              setOpen5(false);
            };
  const org = localStorage.getItem('org');

  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryDesc, setNewCategoryDesc] = useState("");
  const [updateCategoryName, setUpdateCategoryName] = useState("");
  const [updateCategoryDesc, setUpdateCategoryDesc] = useState("");
  const [categoryinfo, setCategoryInfo] = useState("");




  const styleModalVideo = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
  };

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 300,
        sm: 450,
      },
    boxSizing: 'border-box',
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const styleModal2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const top100Films = [
    { title: 'Example Video #1'},
    { title: 'Example Video #2'},
    { title: 'Example Video #3'},
    { title: 'Example Video #4'},
    { title: 'Example Video #5'},
  ]

  const categoryAuto = [
    { title: 'Orchard'},
    { title: 'Packhouse'},
    { title: 'Shop'},
    { title: 'Paddock'},
  ]

  const columns = [
    { 
        field: 'name', 
        headerName: 'Full Name', 
        width: 400,
    },
    { 
      field: 'induction_title', 
      headerName: 'Induction Completed', 
      width: 400,
  },
  { 
    field: 'date_completed', 
    headerName: 'Date', 
    width: 150,
},
{ 
  field: 'time_completed', 
  headerName: 'Time', 
  width: 150,
},


  ];
  

  
  const getInductions = async () => {
    try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/org_completed_inductions/${localStorage.getItem("org_id")}`
        );
        const jsonData = await response.json();
     
        setRows(jsonData);
        console.log(jsonData)
   
       
        
    } catch (err){
        console.log(err.message);
    }
    

   
   
  };

  useEffect(() => {
    getInductions();
   
   
  
   }, []);


  return (
    <Box     
    sx={{
        pl: {
            xs: '0px',
            lg: '60px',
        },
        pt: {
            xs: 6,
            lg: 0,
        },  
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        boxSizing: 'border-box'
    }}>
        <Box         
        sx={{ 
            width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            }, 
            mb: 10
            }}>
        <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
                Please fill out all fields to create a Category.
              </Alert>
            </Snackbar>
            <Snackbar open={open5} autoHideDuration={6000} onClose={handleClose5} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose5}>
                Please fill out all fields to update a Category.
              </Alert>
            </Snackbar>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                        <Link underline="hover" color="inherit" href={`/test/users`}>
                            Dashboard
                        </Link>
                    </Breadcrumbs>
                    <Typography  variant="h4" 
                    sx={{ 
                    fontWeight: '600',
                    fontSize: {
                        xs: 25,
                        md: 30,
                    },
                    }}>
                        Tracker</Typography>
                </Box>
            </Box>
            <Paper sx={{ boxSizing: 'border-box', mb: 2}}>
                <DataGrid
                            sx={{ border: '0px'}}
                            rows={row || {id: "loading"}}
                            columns={columns}
                            pageSize={10}
                            getRowId={(row) => row.id}
                            checkboxSelection
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            autoHeight
                            components={{  Toolbar: QuickSearchToolbar }}
                        />
            </Paper>
        </Box>
    </Box>
  )
}

export default Tracker
