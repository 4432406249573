import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import {
  Box, Container, Typography, Button, FormControl, Paper, Breadcrumbs, Link, Rating, Snackbar, Alert, Modal, CircularProgress, IconButton, Autocomplete, TextField, createTheme, ThemeProvider, Radio, RadioGroup, FormControlLabel, FormLabel
} from '@mui/material/';
import { useNavigate, useParams } from 'react-router-dom';
import { groupBy, isNull } from "lodash";
import Moment from 'moment';
import SignaturePad from "react-signature-canvas";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Logo from '../../../Assets/logoiconwhite.png';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#66BC44', contrastText: 'rgba(255,255,255,0.87)' },
    secondary: { main: '#f50057' },
  },
});

const Induction = () => {
  const navigate = useNavigate();
  const params = useParams();
  const sigCanvas = useRef({});
  const [inductions, setInductions] = useState([]);
  const [usersWatching, setUsersWatching] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);
  const [openError, setOpenError] = useState({ user: false, incorrect: false });
  const [loading, setLoading] = useState(false);

  const clearSignature = () => sigCanvas.current.clear();
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  useEffect(() => {
    (async () => {
      try {
        const [inductionRes, usersRes] = await Promise.all([
          fetch(`${localStorage.getItem("url")}/api/client_user_induction/${params.induction_id}`).then(res => res.json()),
          fetch(`${localStorage.getItem("url")}/api/group_users/${localStorage.getItem("group_id")}/${localStorage.getItem("org_id")}/${params.induction_id}`).then(res => res.json())
        ]);
  
        if (!usersRes.length) {
          navigate(`/group/${localStorage.getItem("group_id")}/${localStorage.getItem("org_id")}`);
        } else {
          setUsersWatching(usersRes);
        }
  
        const shuffledInductions = inductionRes.map(ind => {
          // Split, shuffle and join the answers before setting them
          const shuffledAnswers = ind.answer_text.split(',').sort(() => Math.random() - 0.5).join(',');
          return { ...ind, answer_text: shuffledAnswers };
        });
  
        setInductions(shuffledInductions);
  
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleSubmit = () => {
    if (!currentUser) return setOpenError(prev => ({ ...prev, user: true }));
    let incorrect = [];
    
    inductions.forEach(({ question_id, correct_answer }) => {
      const selected = document.querySelector(`input[name='${question_id}']:checked`);
      if (!selected || selected.value !== correct_answer) incorrect.push(question_id);
    });
    
    if (incorrect.length) return setIncorrectQuestions(incorrect), setOpenError(prev => ({ ...prev, incorrect: true }));
    completeInduction();
  };

  const completeInduction = async () => {
    try {
      setLoading(true);
      const body = {
        user_id: currentUser.user_id,
        induction: inductions[0].induction_id,
        datetime: Moment().format('YYYY-MM-DD-H-mm-s'),
        org: localStorage.getItem("org_id"),
        group_id: localStorage.getItem("group_id"),
        sign: sigCanvas.current.getTrimmedCanvas().toDataURL(),
        q: inductions.map(ind => ind.question_text),
        a: inductions.map(ind => ind.correct_answer)
      };
      await fetch(`${localStorage.getItem("url")}/api/completeInduction`, {
        method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(body)
      });
      await sleep(2000);
      window.location.reload();
    } finally {
      setLoading(false);
    }
  };

  const finishInduction = () => navigate(`/group/${localStorage.getItem("group_id")}/${localStorage.getItem("org_id")}`);

  if (!inductions.length) return <CircularProgress sx={{ color: '#FFFFFF', mb: 3 }} />;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#66BC44', p: 3 }}>
          <Box sx={{ width: { xs: 300, sm: 500, md: 800, lg: 1000, xl: 1200 },  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <IconButton onClick={finishInduction}><img src={Logo} width="50px" height="50px" style={{cursor: 'pointer', objectFit: 'contain'}}/></IconButton>
          </Box>
        </Box>

        <Box sx={{ width: { xs: 300, sm: 500, md: 800, lg: 1000, xl: 1200 }, mt: 2 }}>
          <Paper sx={{ p: 4, display: 'flex', flexDirection: 'column' }}>
            <Typography>User</Typography>
            <Autocomplete
              size='small' sx={{ width: { xs: '100%', md: '60%', xl: '50%' }, mb: 3 }}
              options={usersWatching} getOptionLabel={(u) => u.name} onChange={(e, v) => setCurrentUser(v)}
              renderInput={(params) => <TextField {...params} placeholder='Who is answering the questions?' />}
            />
            <Typography variant='h6' sx={{ mb: 2 }}>Questions</Typography>
            {inductions.map(({ question_id, question_text, answer_text }) => (
              <FormControl key={question_id} sx={{ mb: 3 }}>
                <FormLabel sx={{ color: incorrectQuestions.includes(question_id) ? 'red' : 'black' }}>{question_text}</FormLabel>
                <RadioGroup>
                  {answer_text.split(',').map(answer => (
                    <FormControlLabel key={answer} name={question_id} value={answer} control={<Radio />} label={answer} />
                  ))}
                </RadioGroup>
              </FormControl>
            ))}
            <Box sx={{ border: '1px solid black' }}>
              <SignaturePad ref={sigCanvas} canvasProps={{ className: "signatureCanvas", width: '350', height: '150' }} />
            </Box>
            <Box>
            <IconButton color="primary" onClick={clearSignature}><CloseRoundedIcon /></IconButton>
            </Box>
            <Button onClick={handleSubmit} variant='contained'>Submit</Button>
          </Paper>
        </Box>
      </Box>
      {loading && <Modal open><Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}><CircularProgress /></Box></Modal>}
      <Snackbar open={openError.user} autoHideDuration={6000} onClose={() => setOpenError(prev => ({ ...prev, user: false }))}><Alert severity="error">Please select a user.</Alert></Snackbar>
      <Snackbar open={openError.incorrect} autoHideDuration={6000} onClose={() => setOpenError(prev => ({ ...prev, incorrect: false }))}><Alert severity="error">One or more answers are incorrect.</Alert></Snackbar>
    </ThemeProvider>
  );
};

export default Induction;
