import { Alert, Backdrop, Box, Button, Checkbox, CircularProgress, createTheme, InputAdornment, Paper, Snackbar, TextField, ThemeProvider, Typography } from '@mui/material'
import React, { useState, useEffect, Fragment, useRef } from "react";
import Logo from '../../../Assets/Logo.png'
import md5 from "md5";
import sha1 from "sha1";
import { useNavigate, useParams } from 'react-router-dom'
function Login() {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#66BC44',
        contrastText: 'rgba(255,255,255,0.87)',
      },
      secondary: {
        main: '#f50057',
      },
    },
  });
  const navigate = useNavigate(); 
    const url = localStorage.getItem("url");
    const [org_id, setEmail] = useState();
    const [password, setPassword] = useState();
    const [re, setRe] = useState("HI");
    const [auth, setAuth] = useState("False");

    
  const onSubmitForm = async (e) => {



    
    e.preventDefault();

    try {
      
      const group_id = password;
      const body = { org_id, group_id };
      const response = await fetch(`${localStorage.getItem("url")}/api/groupLogin`, {
        method: "POST",
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         },
        body: JSON.stringify(body),
      });
      console.log(body);
      
      const jsonData = await response.json();
      console.log(jsonData)
      if(jsonData == "failed"){
          setOpen3(true)
      }else{
        setOpen(!open)
        localStorage.setItem("group_id", group_id);
        localStorage.setItem("org_id", org_id);
        localStorage.setItem("category_id",jsonData.induction_category)
        console.log(jsonData.induction_category)
        await new Promise(r => setTimeout(r, 2000));
        navigate(`${group_id}/${org_id}`)
         
      }
      
          
     
        
      
    } catch (err) {
      console.log(err.message);
    }

    
    
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };

  const handleClose3 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen3(false);
  };


  return (
  <ThemeProvider theme={theme}>
          <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose}>
        <Alert variant="outlined" severity="error" sx={{ width: '100%' }} onClose={handleClose}>
          Email or password is incorrect.
        </Alert>
      </Snackbar>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
    </Backdrop>
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
            <form id="login" onSubmit={onSubmitForm} className="login-container"> 
        <Paper 
        sx={{
          width: {
            xs: 300,
            xl: 400,
          },
          alignItems: 'center', 
          justifyContent: 'center', 
          display: 'flex', 
          flexDirection: 'column', 
          boxSizing: 'border-box', 
          padding: {
            xs: 4,
            xl: 10,
          }, 
          mb: 15
          }}>
            <Box
            sx={{
              mb: {
                xs: 2,
              },
            }}>
              <img src={Logo} width='100%'/>
            </Box>

 

            <Box sx={{ width: '100%'}}>
                <Typography>Company ID</Typography>
            </Box>
            
            <TextField 
                sx={{ width: '100%' , pb: 2}} 
                size='small' 
                id="outlined" 
                variant="outlined" 
                placeholder='Company ID'
                onChange={(e) => setEmail(e.target.value)}
            />
            <Box sx={{ width: '100%'}}>
                <Typography>Group ID</Typography>
            </Box>
            <TextField 
                sx={{ width: '100%', pb: 2 }} 
                size='small' 
                id="myform"
                variant="outlined" 
                placeholder='Group ID'
              
                onChange={(e) => setPassword(e.target.value)}
            />
            
        
            <Button type="submit" onClick={onSubmitForm} id="login-button" variant='contained' sx={{ width: '100%', mb: 2 }}>login</Button>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                
              
            </Box>
            <Snackbar open={open3} autoHideDuration={6000} onClose={handleClose3}>
        <Alert variant="outlined" severity="error" sx={{ width: '100%' }} onClose={handleClose3}>
          Induction group ID and org ID not found or induction group already completed.
        </Alert>
      </Snackbar>
           
        </Paper>
        </form>
    </Box>
    </ThemeProvider>
  
    
  )
}

export default Login
