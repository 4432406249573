import React, { useState, useEffect } from 'react';
import { Box, Grid2, Typography, FormControl, TextField, Button, Modal, Card, CardActionArea, CardContent, Autocomplete, Snackbar, Alert, Fab, IconButton, CircularProgress, Paper, InputAdornment } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import RestartAlt from '@mui/icons-material/RestartAlt';
import AddIcon from '@mui/icons-material/Add';
import Moment from 'moment';
import md5 from 'md5';
import sha1 from 'sha1';
import emailjs from '@emailjs/browser';

function addHours(date, hours) {
  date.setTime(date.getTime() + hours * 3600000);
  return date;
}

const ITEM_HEIGHT = 48, ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: { style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 } }
};
const names = ['All', 'Orchard', 'Packhouse', 'Paddock'];

function Users() {
  // State declarations
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [checkboxSelection] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [row, setRows] = useState([]);
  const [inducted, setInducted] = useState([]);
  const [totalinductions, setTotalInductions] = useState([]);
  const [hours, setHours] = useState([]);
  const [user, setUser] = useState({});
  const [updatedName, setUpdatedName] = useState("");
  const [updatedUsername, setUpdatedUserName] = useState("");
  const [updateContactPhone, setUpdatedContactPhone] = useState("");
  const [firstname, setnewFirstName] = useState("");
  const [lastname, setnewLastName] = useState("");
  const [newUsername, setnewUserName] = useState("");
  const [category, setNewCategory] = useState("");
  const [newContactPhone, setnewContactPhone] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [password, setPassword] = useState("");
  const [updatepassword1, setUpdatedPassword1] = useState("");
  const [updatepassword2, setUpdatedPassword2] = useState("");
  const [age, setAge] = useState('');
  const navigate = useNavigate();

  // Helper for closing snackbars/modals
  const handleClose = (setter) => (event, reason) => { if(reason === 'clickaway') return; setter(false); };

  // Email sending function (stubbed)
  const sendEmail = (e) => {};

  const creatUser = async (e) => {
    e.preventDefault();
    if(newUsername === "" || category === "") {
      setOpen6(true);
    } else {
      const token = Math.floor(100000 + Math.random() * 900000);
      const token_date_og = addHours(new Date(), 1);
      const hash_password = md5(sha1(password));
      const form = { token, email: newUsername };
      emailjs.send("service_zg9udxl", "template_a96m8oi", form, "ujgeGjrhqt-YsyTep");
      const org = localStorage.getItem("org_id");
      const current = new Date();
      const date = Moment(current).format('YYYY-MM-DD');
      const token_date = Moment(token_date_og).format('YYYY-MM-DD hh:mm:ss');
      const body = { firstname, lastname, hash_password, newUsername, newContactPhone, org, date, category, token, token_date };
      await fetch(`${localStorage.getItem("url")}/api/addUser`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(body),
      });
      getOrgUsers();
      setOpen2(false);
    }
  };

  const hideEdit = (id) => {
    const edits = document.getElementsByClassName("Edit");
    for(let i = 0; i < edits.length; i++) {
      if(id !== edits[i].id) i++;
      else {
        edits[i].style.display = edits[i].style.display === "none" ? "block" : "none";
      }
    }
  };

  const getStaffInducted = async () => {
    try {
      const response = await fetch(`${localStorage.getItem("url")}/api/staff_inducted_total/${localStorage.getItem("org_id")}`);
      const jsonData = await response.json();
      setInducted(jsonData);
    } catch (err) { console.log(err.message); }
  };

  const getTotalInductions = async () => {
    try {
      const response = await fetch(`${localStorage.getItem("url")}/api/total_inductions/${localStorage.getItem("org_id")}`);
      const jsonData = await response.json();
      setTotalInductions(jsonData);
    } catch (err) { console.log(err.message); }
  };

  const getHoursSaved = async () => {
    try {
      const response = await fetch(`${localStorage.getItem("url")}/api/hours_saved/${localStorage.getItem("org_id")}`);
      const jsonData = await response.json();
      setHours(jsonData);
    } catch (err) { console.log(err.message); }
  };

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      const fullname_split = updatedName.split(" ");
      const fname = fullname_split.slice(0, fullname_split.length - 1).join(" ");
      const lname = fullname_split[fullname_split.length - 1];
      const body = { name: fname, lastname: lname, updatedUsername, updateContactPhone };
      await fetch(`${localStorage.getItem("url")}/api/updateUser/${user.id}`, {
        method: "PUT",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(body),
      });
      window.location.reload();
    } catch(err) { console.error(err.message); }
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    if(updatepassword1 === updatepassword2) {
      try {
        const hash_password = md5(sha1(updatepassword1));
        const body = { hash_password };
        await fetch(`${localStorage.getItem("url")}/api/update_user_password/${user.id}`, {
          method: "PUT",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify(body),
        });
        window.location.reload();
      } catch(err) { console.error(err.message); }
    } else setOpen9(true);
  };

  const handleRowClick = (params) => {
    setUser(params.row);
    setUpdatedContactPhone(params.row.contact_phone);
    setUpdatedName(params.row.name);
    setUpdatedUserName(params.row.username);
  };

  const getOrgUsers = async () => {
    try {
      const response = await fetch(`${localStorage.getItem("url")}/api/users/${localStorage.getItem("org_id")}`);
      const jsonData = await response.json();
      setRows(jsonData);
    } catch(err) { console.log(err.message); }
  };

  const getUser = async (id) => navigate(`${id}`);

  const styleModal = {
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 300, sm: 450 },
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    { field: 'percent', headerName: 'Status', width: 70, renderCell: ({ value }) => (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <CircleIcon sx={{ width: '10px', color: value.length > 3 ? 'green' : value.length > 2 ? 'orange' : 'red', pr: 1 }}/>
      </Box>
    )},
    { field: 'name', headerName: 'Full Name', width: 350, renderCell: (cellValues) => (
      <Box onClick={() => getUser(cellValues.id)} sx={{ cursor: 'pointer' }}>{cellValues.value}</Box>
    )},
    { field: 'username', headerName: 'Email / Username', width: 350 },
    { field: 'actions', type: 'actions', width: 50, renderCell: (params) => (
      <IconButton onClick={() => { setUser(params.row); setUpdatedContactPhone(params.row.contact_phone); setUpdatedName(params.row.name); setUpdatedUserName(params.row.username); setOpen3(true); }}>
        <EditIcon />
      </IconButton>
    )},
    { field: 'reset', type: 'actions', width: 50, renderCell: (params) => (
      <IconButton onClick={() => { setUser(params.row); setOpen8(true); }}>
        <RestartAlt />
      </IconButton>
    )},
  ];

  const [personName, setPersonName] = useState([]);
  const handleName = (event) => {
    const { target: { value } } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
  };

  const getCategories = async () => {
    try {
      const response = await fetch(`${localStorage.getItem("url")}/api/categories/${localStorage.getItem("org_id")}`);
      const jsonData = await response.json();
      setCategories(jsonData);
    } catch(err){ console.log(err.message); }
  };

  const StartInduction = async () => {
    if(selectedCategory === "") setOpen5(true);
    else {
      let category_id = 0;
      categories.forEach((cat) => { if(cat.category_name === selectedCategory) category_id = cat.category_id; });
      localStorage.setItem("category", category_id);
      window.location = `/watch/${category_id}`;
    }
  };

  useEffect(() => {
    getOrgUsers();
    getCategories();
    getStaffInducted();
    getHoursSaved();
    getTotalInductions();
  }, []);

  return (
    <Box sx={{ pl: { xs: '0px', lg: '60px' }, pt: { xs: 6, lg: 0 }, width: '100%', display: 'flex', justifyContent: 'center', boxSizing: 'border-box' }}>
      <Box sx={{ width: { xs: 300, sm: 500, md: 800, lg: 1000, xl: 1200 }, mb: 10 }}>
        <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose(setOpen4)} sx={{ ml: 7 }}>
          <Alert severity="error" onClose={handleClose(setOpen4)}>Please select people to start an Induction.</Alert>
        </Snackbar>
        <Snackbar open={open5} autoHideDuration={6000} onClose={handleClose(setOpen5)} sx={{ ml: 7 }}>
          <Alert severity="error" onClose={handleClose(setOpen5)}>You must select a category before inducting users.</Alert>
        </Snackbar>
        <Snackbar open={open6} autoHideDuration={6000} onClose={handleClose(setOpen6)} sx={{ ml: 7 }}>
          <Alert severity="error" onClose={handleClose(setOpen6)}>Enter all User's information.</Alert>
        </Snackbar>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', my: 5 }}>
          <Typography variant="h4" sx={{ fontWeight: '600', fontSize: { xs: 25, md: 30 } }}>Dashboard</Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            <Button variant='contained' onClick={() => setOpen2(true)}>CREATE USER</Button>
          </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' }, pt: 2 }}>
            <Fab size="small" color="primary" onClick={() => setOpen2(true)} sx={{ zIndex: 1 }}>
              <AddIcon />
            </Fab>
          </Box>
        </Box>
        <Grid2 container spacing={2} sx={{ pb: 2 }}>
          <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
            <Card>
              <CardActionArea>
                <CardContent>
                  <Typography variant='body1'>Employees Onboarded</Typography>
                  <Typography variant='h4' sx={{ fontWeight: '600' }}>{inducted.length || 0}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
            <Card>
              <CardActionArea>
                <CardContent>
                  <Typography variant='body1'>Inductions Completed</Typography>
                  <Typography variant='h4' sx={{ fontWeight: '600' }}>{totalinductions.length || 0}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
            <Card>
              <CardActionArea>
                <CardContent>
                  <Typography variant='body1'>Hours Saved</Typography>
                  <Typography variant='h4' sx={{ fontWeight: '600' }}>{hours.length ? hours[0].hours_saved * totalinductions.length : 0}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid2>
        </Grid2>
        <Box sx={{ width: '100%', mb: 2 }}>
          <Paper>
            <DataGrid
              checkboxSelection={checkboxSelection}
              sx={{ border: '0px' }}
              rows={row || [{ id: "loading" }]}
              onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
              selectionModel={selectionModel}
              columns={columns}
              onRowClick={handleRowClick}
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              slots={{ toolbar: GridToolbar }}
              slotProps={{ toolbar: { showQuickFilter: true } }}
            />
          </Paper>
        </Box>
      </Box>
      <Modal open={open1} onClose={() => setOpen1(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleModal}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography variant='h6'>Induction Category</Typography>
            <CloseIcon onClick={() => setOpen1(false)} />
          </Box>
          <Box sx={{ pb: 3 }}>
            <FormControl sx={{ m: 0, width: '100%', mt: 0, backgroundColor: 'white' }}>
              <Autocomplete
                freeSolo
                size='small'
                sx={{ width: '100%' }}
                disableClearable
                options={categories.map((option) => option.category_name)}
                renderInput={(params) => (
                  <TextField {...params} placeholder='Category' onSelect={(e) => setSelectedCategory(e.target.value)} InputProps={{ ...params.InputProps, type: 'search' }} />
                )}
              />
            </FormControl>
          </Box>
          <Box align='right'>
            <Button onClick={StartInduction} variant='contained'>START INDUCTION</Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={open2} onClose={() => setOpen2(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleModal}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography variant='h6'>User Details</Typography>
            <CloseIcon onClick={() => setOpen2(false)} />
          </Box>
          <Box align="left" mb={1}>
            <Typography>First Name</Typography>
            <TextField sx={{ width: '100%' }} size='small' placeholder='First Name' onChange={(e) => setnewFirstName(e.target.value)} />
          </Box>
          <Box align="left" mb={1}>
            <Typography>Last Name</Typography>
            <TextField sx={{ width: '100%' }} size='small' placeholder='Last Name' onChange={(e) => setnewLastName(e.target.value)} />
          </Box>
          <Box align="left" mb={1}>
            <Typography>Email</Typography>
            <TextField sx={{ width: '100%' }} size='small' placeholder='Email' onChange={(e) => setnewUserName(e.target.value)} />
          </Box>
          <Box align="left" mb={1}>
            <Typography>Password</Typography>
            <TextField sx={{ width: '100%' }} size='small' placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
          </Box>
          <Box sx={{ mb: 4 }}>
            <Typography>Category</Typography>
            <Autocomplete
              size='small'
              sx={{ width: '100%' }}
              disableClearable
              onChange={(a, b) => setNewCategory(b.category_id)}
              options={categories}
              getOptionLabel={(category) => category.category_name}
              renderInput={(params) => (
                <TextField {...params} placeholder='Category' InputProps={{ ...params.InputProps, type: 'search' }} />
              )}
            />
          </Box>
          <Box align='right'>
            <Button onClick={creatUser} variant='contained'>Create</Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={open3} onClose={() => setOpen3(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleModal}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography variant='h6'>User Details</Typography>
            <CloseIcon onClick={() => setOpen3(false)} />
          </Box>
          <Box align="left" mb={1}>
            <Typography>Full Name</Typography>
            <TextField sx={{ width: '100%' }} size='small' placeholder='Full Name' defaultValue={user.name} onChange={(e) => setUpdatedName(e.target.value)} />
          </Box>
          <Box align="left" mb={3}>
            <Typography>Email</Typography>
            <TextField sx={{ width: '100%' }} size='small' placeholder='Email' defaultValue={user.username} onChange={(e) => setUpdatedUserName(e.target.value)} />
          </Box>
          <Box align='right'>
            <Button variant='contained' onClick={updateUser}>Update</Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={open8} onClose={() => setOpen8(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleModal}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography variant='h6'>Password Reset</Typography>
            <CloseIcon onClick={() => setOpen8(false)} />
          </Box>
          <Box align="left" mb={1}>
            <Typography>Password</Typography>
            <TextField sx={{ width: '100%' }} size='small' placeholder='Password' onChange={(e) => setUpdatedPassword1(e.target.value)} />
          </Box>
          <Box align="left" mb={3}>
            <Typography>Confirm Password</Typography>
            <TextField sx={{ width: '100%' }} size='small' placeholder='Confirm Password' onChange={(e) => setUpdatedPassword2(e.target.value)} />
          </Box>
          <Box align='right'>
            <Button variant='contained' onClick={updatePassword}>Update</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Users;
