import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import {
  Box, Container, Typography, Button, Modal, Select, TextField, InputAdornment,
  FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link, IconButton, Autocomplete,
  Snackbar, Alert, Fab, Card, CardContent, CardActionArea
} from '@mui/material';
import { DataGrid, GridToolbarQuickFilter, GridActionsCellItem } from '@mui/x-data-grid';
import { Search as SearchIcon, Add as AddIcon, Movie as MovieIcon, Image as ImageIcon, Title as TitleIcon, Description as DescriptionIcon, Edit as EditIcon, Close, DeleteOutline, Add, Circle as CircleIcon, QuestionMark as QuestionMarkIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import Moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import Thumbnail from '../../Assets/thumbnail.jpg';

function QuickSearchToolbar() {
  return (
    <Box sx={{ p: 2, boxSizing: 'border-box', pb: 0, display: 'flex', justifyContent: 'space-between' }}>
      <GridToolbarQuickFilter sx={{ width: '100%' }} />
    </Box>
  );
}

const ITEM_HEIGHT = 48, ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function InductionGroup() {
  const navigate = useNavigate();
  const params = useParams();
  const org = localStorage.getItem('org');
  const id = localStorage.getItem('induction_group_id');

  const [category, setNewCategory] = useState("");
  const [row, setRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [group, setGroup] = useState([]);
  const [videos, setVideos] = useState([]);
  const [newUser, setGroupUser] = useState([]);
  const [userToRemove, setUserToRemove] = useState([]);
  const [categories, setCategories] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryDesc, setNewCategoryDesc] = useState("");
  const [updateCategoryName, setUpdateCategoryName] = useState("");
  const [updateCategoryDesc, setUpdateCategoryDesc] = useState("");
  const [categoryinfo, setCategoryInfo] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [updatedName, setUpdatedName] = useState("");
  const [updatedUsername, setUpdatedUserName] = useState("");
  const [updateContactPhone, setUpdatedContactPhone] = useState("");
  const [firstname, setnewFirstName] = useState("");
  const [lastname, setnewLastName] = useState("");
  const [newUsername, setnewUserName] = useState("");
  const [newContactPhone, setnewContactPhone] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [password, setPassword] = useState("");
  const [updatepassword1, setUpdatedPassword1] = useState("");
  const [updatepassword2, setUpdatedPassword2] = useState("");
  const [val, setVal] = useState([
    { firstname: "", lastname: "", newUsername: "", newContactPhone: "" }
  ]);

  const styleModalVideo = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
    width: 1200, overflow: 'hidden', borderRadius: '4px', boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
  };

  const styleModal = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
    width: { xs: 300, sm: 450 }, boxSizing: 'border-box', bgcolor: 'white',
    borderRadius: '4px', boxShadow: 24, p: 4,
  };

  const styleModal2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 300, sm: 550 },
    maxHeight: '90vh', // Limit height to 90% of viewport height
    overflowY: 'auto', // Enable vertical scrolling when content overflows
    boxSizing: 'border-box',
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen3 = () => setOpen3(true);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);
  const handleClose4 = (e, reason) => { if (reason !== 'clickaway') setOpen4(false); };
  const handleClose5 = (e, reason) => { if (reason !== 'clickaway') setOpen5(false); };

  const updateGroupUser = (id) => {
    console.log(id);
    setGroupUser(prev => [...prev, id]);
  };

  const getUser = async (group) => {
    console.log(group);
    navigate(`${id}`);
  };

  const columns = [
    { field: 'name', headerName: 'Full Name', width: 300 },
    { field: 'completed', headerName: 'Inductions Completed', width: 300 },
    { field: 'count', headerName: 'Total Inductions', width: 300 },
    { field: 'actions', type: 'actions', width: 80, renderCell: (params) => {
        const onClickEdit = () => {
          setCategoryInfo(params.row);
          setUpdateCategoryName(params.row.category_name);
          setUpdateCategoryDesc(params.row.category_desc);
          setOpen2(true);
        };
        return group[0]?.status === 1 ? null : (
          <IconButton onClick={onClickEdit}>
            <EditIcon />
          </IconButton>
        );
      }
    },
  ];

  const getGroup = async () => {
    try {
      console.log(params.id);
      const response = await fetch(`${localStorage.getItem("url")}/api/group/${params.id}}`);
      const jsonData = await response.json();
      setGroup(jsonData);
      localStorage.setItem("induction_group_id", jsonData[0].category_id);
    } catch (err) {
      console.log(err.message);
    }
  };

  const addGroup = async (e) => {
    e.preventDefault();
    const usersList = newUser.map(user => user.id);
    if (!usersList.length) {
      setOpen4(true);
    } else {
      const body = { users: usersList, group_id: params.id };
      await fetch(`${localStorage.getItem("url")}/api/addUserGroup`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(body),
      });
      setOpen1(false);
      getGroupUsers();
      window.location.reload();
    }
  };

  const getUsers = async () => {
    try {
      const response = await fetch(`${localStorage.getItem("url")}/api/users/${localStorage.getItem("org_id")}`);
      const jsonData = await response.json();
      const response2 = await fetch(`${localStorage.getItem("url")}/api/usersAlreadyIn/${localStorage.getItem("org_id")}/${localStorage.getItem("group_id")}`);
      const jsonData2 = await response2.json();
      setUsers(jsonData2.length ? jsonData.filter(({ name }) => !jsonData2.some(e => e.name === name)) : jsonData);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getGroupUsers = async () => {
    try {
      const response = await fetch(`${localStorage.getItem("url")}/api/getGroupUsers/${params.id}`);
      const jsonData = await response.json();
      setRows(jsonData);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getCategories = async () => {
    try {
      const response = await fetch(`${localStorage.getItem("url")}/api/categories/${localStorage.getItem("org_id")}`);
      const jsonData = await response.json();
      setCategories(jsonData);
    } catch(err){ console.log(err.message); }
  };

  const StartInduction = async () => {
    if(selectedCategory === "") setOpen5(true);
    else {
      let category_id = 0;
      categories.forEach((cat) => { if(cat.category_name === selectedCategory) category_id = cat.category_id; });
      localStorage.setItem("category", category_id);
      window.location = `/watch/${category_id}`;
    }
  };

  function generateRandom() {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return Array.from({ length: 8 }, () => charset.charAt(Math.floor(Math.random() * charset.length))).join("");
  }

  const handleRowClick = (params) => {
    setUserToRemove(params.row.user_id);
  };

  const deleteQuestion = async () => {
    try {
      const body = { group_id: params.id, user_id: userToRemove };
      await fetch(`${localStorage.getItem("url")}/api/removeUser`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(body),
      });
      window.location.reload();
    } catch (err) {
      console.log(err.message);
    }
  };

  const createUser = async (e) => {
    e.preventDefault();
    // Check if there is at least one user and a category has been selected
    if (val.length === 0 || category === "") {
      setOpen6(true);
      return;
    }
    
    const org = localStorage.getItem("org_id");
    const group_id = params.id; // or however you store it
    const current = new Date();
    const date = Moment(current).format('YYYY-MM-DD');
  
    // Map over the array of user objects
    const usersToAdd = val.map((user) => ({
      firstname: user.firstname,
      lastname: user.lastname,
      newContactPhone: user.newContactPhone || "",
      org,
      date,
      category,
    }));
  
    // Send the new users along with the group_id to the backend
    await fetch(`${localStorage.getItem("url")}/api/AddGroupUser`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ users: usersToAdd, group_id }),
    });
    
    window.location.reload();
  };
  

  
  const handleAdd=()=>{
    const abc=[...val,[]]
    setVal(abc)
}

  const handleDelete=(i)=>{
    const deletVal=[...val]
    deletVal.splice(i,1)
    setVal(deletVal)  
}

  const startInduction = () => {
    navigate(`${id}`);
  };

  useEffect(() => {
    getGroup();
    getUsers();
    getGroupUsers();
    getCategories();
  }, []);

  return (
    <Box sx={{
      pl: { xs: '0px', lg: '60px' },
      pt: { xs: 6, lg: 0 },
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      boxSizing: 'border-box'
    }}>
      <Box sx={{ width: { xs: 300, sm: 500, md: 800, lg: 1000, xl: 1200 }, mb: 10 }}>
        <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
          <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
            Please fill out all fields to create a Category.
          </Alert>
        </Snackbar>
        <Snackbar open={open5} autoHideDuration={6000} onClose={handleClose5} sx={{ ml: 7 }}>
          <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose5}>
            Please fill out all fields to update a Category.
          </Alert>
        </Snackbar>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', mb: 2 }}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 5 }}>
              <Link underline="hover" color="inherit" href={`/test/users`}>Dashboard</Link>
            </Breadcrumbs>
            {group.length ? (
              <Box sx={{ display: 'flex' }}>
                <Typography variant="h4" sx={{ fontWeight: '600', pr: 2 }}>Group ID:</Typography>
                <Typography variant="h4" sx={{ fontWeight: '600', pr: 2 }}>{group[0].id}</Typography>
                <Typography variant="h4" sx={{ fontWeight: '600', pr: 2 }}>Company ID:</Typography>
                <Typography variant="h4" sx={{ fontWeight: '600' }}>{group[0].org_id}</Typography>
              </Box>
            ) : <Typography variant="h4" sx={{ fontWeight: '600' }}>loading..</Typography>}
          </Box>
          <Box sx={{display: 'flex'}}>
            <Button onClick={handleOpen1} variant="contained" startIcon={<AddIcon />} sx={{ display: { xs: 'none', md: 'flex'}, mr: 2 }}>
              Add
            </Button>
            <Button variant='contained' onClick={() => setOpen2(true)}>CREATE USER</Button>
            <Fab size="small" color="primary" aria-label="add" onClick={handleOpen1} sx={{ display: { xs: 'block', md: 'none' }, zIndex: 1 }}>
              <Add />
            </Fab>
          </Box>
        </Box>
        <Paper sx={{ mb: 2 }}>
          <DataGrid
            sx={{ border: '0px' }}
            rows={row || { id: "loading" }}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row.id}
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: QuickSearchToolbar }}
            disableMultipleRowSelection
            onRowClick={handleRowClick}
          />
        </Paper>
        {group.length && group[0].status === 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="contained" onClick={deleteQuestion} sx={{ height: '40px', mb: 10, display: { xs: 'none', sm: 'block' } }}>
              Remove user
            </Button>
          </Box>
        )}
      </Box>
      <Modal open={open1} onClose={handleClose1} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleModal}>
          <Box sx={{ position: 'relative', mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="h6">Add User</Typography>
              <IconButton onClick={handleClose1}><Close /></IconButton>
            </Box>
            <Typography>User</Typography>
            <Autocomplete
              size="small"
              sx={{ width: '100%', pb: 3 }}
              disableClearable
              onChange={(event, value) => setGroupUser(value)}
              options={users}
              getOptionLabel={(option) => option.name}
              multiple
              renderInput={(params) => (
                <TextField {...params} placeholder="User" InputProps={{ ...params.InputProps, type: 'search' }} />
              )}
            />
          </Box>
          <Box align="right">
            <Button variant="contained" onClick={addGroup}>ADD</Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={open2} onClose={() => setOpen2(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleModal2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography variant='h6'>User Details</Typography>
            <CloseIcon onClick={() => setOpen2(false)} />
          </Box>
          {val.map((data, i) => (
            <Box key={i} sx={{ mb: 1, display: 'flex' }}>
              <Box align="left" mb={1}>
                <Typography>First Name</Typography>
                <TextField
                  sx={{ width: '90%' }}
                  size="small"
                  placeholder="First Name"
                  value={data.firstname || ""}
                  onChange={(e) => {
                    const newVal = [...val];
                    newVal[i] = { ...newVal[i], firstname: e.target.value };
                    setVal(newVal);
                  }}
                />
              </Box>
              <Box align="left" mb={1}>
                <Typography>Last Name</Typography>
                <TextField
                  sx={{ width: '100%' }}
                  size="small"
                  placeholder="Last Name"
                  value={data.lastname || ""}
                  onChange={(e) => {
                    const newVal = [...val];
                    newVal[i] = { ...newVal[i], lastname: e.target.value };
                    setVal(newVal);
                  }}
                />
              </Box>
              <IconButton onClick={() => handleDelete(i)}>
                <Close />
              </IconButton>
            </Box>
          ))}
          <Box sx={{ mb: 2 }}>
              <Button variant='contained' onClick={()=>handleAdd()}>Add User</Button>
          </Box>
          <Box sx={{ mb: 4 }}>
            <Typography>Category</Typography>
            <Autocomplete
              size='small'
              sx={{ width: '100%' }}
              disableClearable
              onChange={(a, b) => setNewCategory(b.category_id)}
              options={categories}
              getOptionLabel={(category) => category.category_name}
              renderInput={(params) => (
                <TextField {...params} placeholder='Category' InputProps={{ ...params.InputProps, type: 'search' }} />
              )}
            />
          </Box>
          <Box align='right'>
            <Button onClick={createUser} variant='contained'>Create</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default InductionGroup;
