import React, { Fragment,useState, useEffect } from 'react';
import { Box, Container, Typography, Grid, InputLabel, Select, MenuItem, FormControl, TextField, Button, Modal, Card, CardActionArea, CardContent, IconButton, CircularProgress, Snackbar, Alert } from '@mui/material/'
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router';
import Close from '@mui/icons-material/Close';
import { HexColorInput } from "react-colorful";
import EditIcon from '@mui/icons-material/Edit';
import LoginIcon from '@mui/icons-material/Login';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import md5 from "md5";
import sha1 from "sha1";
import { RestartAlt } from '@mui/icons-material';

function QuickSearchToolbarClient() {
    return (
      <Box
        sx={{
          p: 2,
          boxSizing: 'border-box',
          pb: 0,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant='h6'>Clients</Typography>
        <GridToolbarQuickFilter sx={{ width: '90%'}} />
      </Box>
    );
  }

  function QuickSearchToolbarAdmin() {
    return (
      <Box
        sx={{
          p: 2,
          boxSizing: 'border-box',
          pb: 0,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant='h6'>Admins</Typography>
        <GridToolbarQuickFilter sx={{ width: '90%'}} />
      </Box>
    );
  }
  
  
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

function HomeV2() {
    const [color, setColor] = useState("#aabbcc");

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    const [open3, setOpen3] = useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);
    const [open4, setOpen4] = useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);
    const [open5, setOpen5] = useState(false);
    const handleOpen5 = () => setOpen5(true);
    const handleClose5 = () => setOpen5(false);
    const [open6, setOpen6] = useState(false);
    const handleOpen6 = () => setOpen6(true);
    const handleClose6 = () => setOpen6(false);
    const [open11, setOpen11] = useState(false);
    const handleOpen11 = () => setOpen11(true);
    const handleClose11 = () => setOpen11(false);
    // Creating Admin Error Alert
    const [open7, setOpen7] = React.useState(false);
    const handleOpen7 = () => setOpen7(true);
    const handleClose7 = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    

    setOpen7(false);
    };

    // Update Admin Error Alert
    const [open8, setOpen8] = React.useState(false);
    const handleOpen8 = () => setOpen8(true);
    const handleClose8 = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    
    

    setOpen8(false);
    };

    // Create Client Error Alert
    const [open9, setOpen9] = React.useState(false);
    const handleOpen9 = () => setOpen9(true);
    const handleClose9 = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen9(false);
    };

    // Update Client Error Alert
    const [open10, setOpen10] = React.useState(false);
    const handleOpen10 = () => setOpen10(true);
    const handleClose10 = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen10(false);
    };

        // Reset Admin Password
        const [open12, setOpen12] = React.useState(false);
        const handleOpen12 = () => setOpen12(true);
        const handleClose12 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpen12(false);
        };

    const [clients, setClients] = useState([]);
    const [category, setAge] = useState('');
    const [row, setRows] = useState([]);
    const [users, setUsers] = useState([]);
    const [new_organisation_name, setNewOrgName] = useState("")
    const [new_contact_name, setNewContactName] = useState("");
    const [new_contact_email, setNewContactEmail] = useState("");
    const [new_contact_phone, setNewContactPhone] = useState("");
    const [new_hours, setNewHours] = useState("");
    const [client, setUpdateClient] = useState("")
    const [update_organisation_name, setUpdateOrgName] = useState("")
    const [update_contact_name, setUpdateContactName] = useState("");
    const [update_contact_email, setUpdateContactEmail] = useState("");
    const [update_contact_phone, setUpdateContactPhone] = useState("");
    const [update_hours, setUpdateHours] = useState("");
    const [totalonboarded, setTotalOnboarded] = useState("");
    const [totalinductions, setTotalInductions] = useState("");
    const [updateadmin, setUpdateAdmin] = useState([]);
    const handleChange = (event) => {
      setAge(event.target.value);
    };


    const getStaffInducted = async () => {
        try{
          const response = await fetch(
              `${localStorage.getItem("url")}/api/totalOnboarded`
          );
          const jsonData = await response.json();
       
          setTotalOnboarded(jsonData);
          console.log(jsonData)
     
         
          
      } catch (err){
          console.log(err.message);
      }
      
    
     
     
    }; 

    const getInducted = async () =>{
        try{
          const response = await fetch(
              `${localStorage.getItem("url")}/api/totalInductions`
          );
          const jsonData = await response.json();
       
          setTotalInductions(jsonData);
          console.log(jsonData)
     
         
          
      } catch (err){
          console.log(err.message);
      }
      
    
     
     
    }; 

    const createClient = async (e) => {
        e.preventDefault();
        if(new_organisation_name === ""){
            setOpen9(true);
          }

          else {
            const body = { new_organisation_name,new_contact_name,new_contact_email,new_contact_phone, new_hours };
            console.log(body);
          const response = await fetch(
            `${localStorage.getItem("url")}/api/addClient`,
            {
              method: "POST",
              headers: { "content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
    
          window.location.reload();
        }
      };

      const getClient = async (id) => {
     

        console.log(id);
        navigate(`${id}`)
       
       
      
    }

    const updateClient = async (e) => {


        e.preventDefault();
        if(update_organisation_name === ""){
            setOpen9(true);
          }
      
          else if(update_contact_name === ""){
            setOpen9(true);
          }
    
          else if(update_contact_email === ""){
            setOpen9(true);
          }
    
          else if(update_contact_phone === ""){
            setOpen9(true);
          }

          else if(update_hours === ""){
            setOpen9(true);
          }

          else {
            try {

              const body = { update_organisation_name,update_contact_name,update_contact_email,update_contact_phone, update_hours };
              console.log(body)
              const response = await fetch(
                `${localStorage.getItem("url")}/api/updateClient/${client.id}`,
                {
                  method: "PUT",
                  headers: { "Content-type": "application/json" },
                  body: JSON.stringify(body),
                }
              );
        
              window.location.reload();
            } catch (err) {
              console.error(err.message);
            }
          }

    };

    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [username, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [updatefirst_name, setUpdateFirstName] = useState("");
    const [updatelast_name, setUpdateLastName] = useState("");
    const [updateusername, setUpdateEmail] = useState("");
    const [updatepassword, setUpdatePassword] = useState("");
    const [updatephone, setUpdatePhone] = useState("");
  
    const [updatepassword1, setUpdatedPassword1] = useState("");
    const [updatepassword2, setUpdatedPassword2] = useState("");

    const createAdmin = async (e) => {
      e.preventDefault();
  
      if(first_name === ""){
        setOpen7(true);
      }
  
      else if(last_name === ""){
        setOpen7(true);
      }

      else if(username === ""){
        setOpen7(true);
      }

      else if(password === ""){
        setOpen7(true);
      }

      else if(phone === ""){
        setOpen7(true);
      }

    else {
      try {
        const hash_password = md5(sha1(password));
        const body = { first_name, last_name, username, hash_password, phone };
        const response = await fetch(
          `${localStorage.getItem("url")}/api/registerAdmin`,
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
          }
        );
  
        window.location.reload();
      } catch (err) {
        console.log(err.message);
      }
        }
    };
    
    const updateAdmin = async (e) => {


        e.preventDefault();
        if(updatefirst_name === ""){
            setOpen8(true);
          }
      
          else if(updatelast_name === ""){
            setOpen8(true);
          }
    
          else if(updateusername === ""){
            setOpen8(true);
          }
    
          else if(updatephone === ""){
            setOpen8(true);
          }

          else {
            try {

              const body = { updatefirst_name, updatelast_name, updateusername, updatephone };
              console.log(body)
              const response = await fetch(
                `${localStorage.getItem("url")}/api/updateAdmin/${updateadmin.id}`,
                {
                  method: "PUT",
                  headers: { "Content-type": "application/json" },
                  body: JSON.stringify(body),
                }
              );
        
              window.location.reload();
            } catch (err) {
              console.error(err.message);
            }
          }
        
    };

    const updatePassword = async (e) => {


      e.preventDefault();
         if (updatepassword1 == updatepassword2){
          try {
            const hash_password = md5(sha1(updatepassword1));
            const body = { hash_password };
            console.log(body)
            const response = await fetch(
              `${localStorage.getItem("url")}/api/update_user_password/${updateadmin.id}`,
              {
                method: "PUT",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(body),
              }
            );
      
            window.location.reload();
          } catch (err) {
            console.error(err.message);
          }
         } else {
          setOpen12(true);
         }

      

  };


    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: 300,
            sm: 450,
          },
        boxSizing: 'border-box',
        bgcolor: 'white',
        borderRadius: '4px',
        boxShadow: 24,
        p: 4,
      };

    const columns = [
        { 
            field: 'organisation_name', 
            headerName: 'Business Name', 
            width: 225,
            renderCell: (cellValues) => {
                return (
                    <Box onClick={() => {getClient(cellValues.id)}} sx={{ cursor: 'pointer'}}>
                        {cellValues.value}
                    </Box>
                        
                )
            }
        },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 75,
            renderCell: (cellValues) => {
                if ( cellValues.value == 3 ) {
                  return (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircleIcon sx={{ width: '10px', color: 'green', pr: 1}}/>
                    </Box>
                )
                }
    
                else {
                  return (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircleIcon sx={{ width: '10px', color: 'red', pr: 1}}/>
                    </Box>
                )
                }
    
            }
        },
        { field: 'contact_name', headerName: 'Contact', width: 200 },
        { field: 'contact_email', headerName: 'Email', width: 250 },
        { field: 'contact_phone', headerName: 'Phone', width: 140 },
        

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Edit',
            width: 80,
            renderCell: (params) => {
                const onClickEdit = async () => {
                  setUpdateClient(params.row)
                  setUpdateOrgName(params.row.organisation_name);
                  setUpdateContactName(params.row.contact_name);
                  setUpdateContactEmail(params.row.contact_email);
                  setUpdateContactPhone(params.row.contact_phone);
                  setUpdateHours(params.row.hours_saved);
                  setOpen4(true);
                };
          
                return (
                  <>
                    <IconButton onClick={onClickEdit}>
                      <EditIcon />
                    </IconButton>
                  </>
                );
              },
          },
          {   
    
            field: 'activate',
            type: 'actions',
            headerName: 'Change Status',
            width: 125,
           
            renderCell: (params) => {
              const onClickActivate = async () => {
                setUpdateClient(params.row);
                setOpen5(true);
              };
    
              const onClickDeactivate = async () => {
                setUpdateClient(params.row);
                setOpen6(true);
                
              };
        
              if (params.row.status == 3) {
              return (
                <>
                  <Button onClick={onClickDeactivate} sx={{color: 'GrayText'}}>Deactivate</Button>
                </>
              );
              }
              else {
                return (
                    <>
                      <Button onClick={onClickActivate} sx={{color: 'GrayText'}}>Activate</Button>
                    </>
                  );
              }
            },
          },

      ];

      const activateClient = async (e) => {     
        try {

            const body = { };
          console.log(body)
          const response = await fetch(
            `${localStorage.getItem("url")}/api/activateClient/${client.id}`,
            {
              method: "PUT",
              headers: { "Content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
    
          window.location.reload();
        } catch (err) {
          console.error(err.message);
        }
      };

      const deactivateClient = async (e) => {     
        try {

            const body = { };
          console.log(body)
          const response = await fetch(
            `${localStorage.getItem("url")}/api/deactivateClient/${client.id}`,
            {
              method: "PUT",
              headers: { "Content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
    
          window.location.reload();
        } catch (err) {
          console.error(err.message);
        }
      };

      const columns2 = [
        { 
            field: 'name', 
            headerName: 'Name', 
            width: 350,
        },
        { field: 'username', headerName: 'Email', width: 300 },
        { field: 'phone', headerName: 'Phone', width: 200 },
        

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Edit',
            width: 80,
            renderCell: (params) => {
                const onClickEdit = async () => {
                  setUpdateAdmin(params.row)
                  setUpdatePhone(params.row.phone);
                  setUpdateFirstName(params.row.firstname);
                  setUpdateLastName(params.row.lastname);
                  setUpdateEmail(params.row.username);
                  setOpen3(true);
                  console.log(updateadmin)
                };
          
                return (
                  <>
                    <IconButton onClick={onClickEdit}>
                      <EditIcon />
                    </IconButton>
                  </>
                );
              },
          },
          {
            field: 'reset',
            type: 'actions',
            headerName: 'Reset',
            width: 80,
            renderCell: (params) => {
                const onClickEdit = async () => {
                  setUpdateAdmin(params.row)
                  setOpen11(true);
                };
          
                return (
                  <>
                    <IconButton onClick={onClickEdit}>
                      <RestartAlt />
                    </IconButton>
                  </>
                );
              },
          },

      ];
      
   
      
    


      const getClients = async () => {
        try{
            const response = await fetch(
                `${localStorage.getItem("url")}/api/org`
            );
            const jsonData = await response.json();
         
            setRows(jsonData);
           setClients(jsonData);
           
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };

      const getUsers = async () => {
        try{
            const response = await fetch(
                `${localStorage.getItem("url")}/api/adminusers/${localStorage.getItem("org_id")}`
            );
            const jsonData = await response.json();
            console.log(users)
            setUsers(jsonData);
           
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };

   
      
    useEffect(() => {
        getClients();
        getUsers();
        getStaffInducted();
        getInducted();
       
       
      
       }, []);

       const logRows = async () => {
        console.log(row)
       }

       

       const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pl: '60px', boxSizing: 'border-box'}}>
        <Snackbar open={open7} autoHideDuration={6000} onClose={handleClose7} sx={{ ml: 7 }}>
            <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose7}>
            Please fill out all fields to create an Admin.
            </Alert>
        </Snackbar>
        <Snackbar open={open8} autoHideDuration={6000} onClose={handleClose8} sx={{ ml: 7 }}>
            <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose8}>
            Please fill out all fields to update an Admin.
            </Alert>
        </Snackbar>
        <Snackbar open={open9} autoHideDuration={6000} onClose={handleClose9} sx={{ ml: 7 }}>
            <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose9}>
            Please fill out all fields to create an Client.
            </Alert>
        </Snackbar>
        <Snackbar open={open10} autoHideDuration={6000} onClose={handleClose10} sx={{ ml: 7 }}>
            <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose10}>
            Please fill out all fields to update an Client.
            </Alert>
        </Snackbar>
        <Snackbar open={open12} autoHideDuration={6000} onClose={handleClose12} sx={{ ml: 7 }}>
            <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose12}>
            Passwords do not match.
            </Alert>
        </Snackbar>
        <Box sx={{ width: '1200px'}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2, marginTop: 5, }}>
                <Typography  variant="h4" sx={{ fontWeight: '600'}}>Dashboard</Typography>
                <Box>
                    <Button variant="contained" onClick={handleOpen} startIcon={<AddIcon />} sx={{ mr: 2}}>
                        Add Client
                    </Button>
                    <Button variant="contained" onClick={handleOpen2} startIcon={<AddIcon />}>
                        Add admin
                    </Button>
                </Box>
            </Box>
            <Grid container spacing={2} sx={{ pb: 2}}>
                <Grid xs={4}>
                    <Card>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant='body1'>Employees Onboarded</Typography>
                                {totalonboarded.length 
                                    ?
                                      <Typography variant='h4' sx={{ fontWeight: '600'}}>{totalonboarded.length}</Typography>
                                    :
                                    <CircularProgress sx={{color: '#757575'}}/>
                                }
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant='body1'>Inductions Completed</Typography>
                                {totalinductions.length 
                                    ?
                                      <Typography variant='h4' sx={{ fontWeight: '600'}}>{totalinductions.length}</Typography>
                                    :
                                    <CircularProgress sx={{color: '#757575'}}/>
                                }
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
              </Grid>
            <Box sx={{ width: '100%', mb: 2}}>
                <Paper>
                    <DataGrid
                        sx={{ border: '0px'}}
                        rows={row || {id: "loading"}}
                        columns={columns}
                        checkboxSelection
                        pageSize={10}
                        experimentalFeatures={{ newEditingApi: true }}
                        autoHeight
                        components={{  Toolbar: QuickSearchToolbarClient }}
                    
                    />
                </Paper>
            </Box>
            <Box sx={{ width: '100%', mb: 2}}>
                <Paper>
                    <DataGrid
                        sx={{ border: '0px'}}
                        rows={users || {id: "loading"}}
                        columns={columns2}
                        checkboxSelection
                        pageSize={10}
                        experimentalFeatures={{ newEditingApi: true }}
                        autoHeight
                        components={{  Toolbar: QuickSearchToolbarAdmin }}
                    
                    />
                </Paper>
            </Box>
            <Box align='left' mb={15}>
                <Button variant="contained" sixe='lg' sx={{ width: '150px', marginRight: 2}}>
                    disable
                </Button>
            </Box>
        </Box>
        <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Company Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose}/>
                        </IconButton>
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>Company Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Company Name'
                                defaultValue=''
                                onSelect={(e) => setNewOrgName(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Hours Taken for Induction.</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Induction Hours'
                                defaultValue=''
                                onSelect={(e) => setNewHours(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Contact Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Name'
                                defaultValue=''
                                onSelect={(e) => setNewContactName(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Contact Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Email'
                                defaultValue=''
                                onSelect={(e) => setNewContactEmail(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Contact Phone</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Phone'
                                defaultValue=''
                                onSelect={(e) => setNewContactPhone(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button onClick={createClient} variant='contained'>Create</Button>
                        </Box>
                </Box>
            </Modal>
            <Modal
                open={open4}
                onClose={handleClose4}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Company Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose4}/>
                        </IconButton>
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>Company Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Company Name'
                                defaultValue={client.organisation_name}
                                onChange={(e) => setUpdateOrgName(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Hours Taken for Induction.</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Induction Hours'
                                defaultValue={client.hours_saved}
                                onChange={(e) => setUpdateHours(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Contact Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Name'
                                defaultValue={client.contact_name}
                                onChange={(e) => setUpdateContactName(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Contact Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Email'
                                defaultValue={client.contact_email}
                                onChange={(e) => setUpdateContactEmail(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Contact Phone</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Phone'
                                defaultValue={client.contact_phone}
                                onChange={(e) => setUpdateContactPhone(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button onClick={(e) => updateClient(e)} variant='contained'>Update</Button>
                        </Box>
                </Box>
            </Modal>
            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Admin Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose2}/>
                        </IconButton>
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>First Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='First Name'
                                defaultValue=''
                                onSelect={(e) => setFirstName(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Last Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Last Name'
                                defaultValue=''
                                onSelect={(e) => setLastName(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Email'
                                defaultValue=''
                                onSelect={(e) => setEmail(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Phone</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Phone'
                                defaultValue=''
                                onSelect={(e) => setPhone(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Password</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Password'
                                defaultValue=''
                                onSelect={(e) => setPassword(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button onClick={createAdmin} variant='contained'>Create</Button>
                        </Box>
                </Box>
            </Modal>
            <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Admin Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose3}/>
                        </IconButton>
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>First Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='First Name'
                                defaultValue={updateadmin.firstname}
                                onChange={(e) => setUpdateFirstName(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Last Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Last Name'
                                defaultValue={updateadmin.lastname}
                                onChange={(e) => setUpdateLastName(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Email'
                                defaultValue={updateadmin.username}
                                onChange={(e) => setUpdateEmail(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Phone</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Phone'
                                defaultValue={updateadmin.phone}
                                onChange={(e) => setUpdatePhone(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button onClick={(e) => updateAdmin(e)} variant='contained'>Update</Button>
                        </Box>
                </Box>
            </Modal>
            <Modal
                open={open5}
                onClose={handleClose5}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                            <Typography variant='h6'>Are you sure you want to activate this client?</Typography>
                        </Box>
                        <Button onClick={activateClient}>Yes</Button>
                        <Button onClick={handleClose5}>No</Button>
                        
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open6}
                onClose={handleClose6}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                            <Typography variant='h6'>Are you sure you want to deactivate this client?</Typography>
                        </Box>
                        <Button onClick={deactivateClient}>Yes</Button>
                        <Button onClick={handleClose6}>No</Button>
                        
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open11}
                onClose={handleClose11}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Password Reset</Typography>
                        <Close onClick={handleClose11} />
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>Password</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Password'
                                onChange={(e) => setUpdatedPassword1(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Confirm Password</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Confirm Password'
                                onChange={(e) => setUpdatedPassword2(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={(e) => updatePassword(e)}>Update</Button>
                        </Box>
                </Box>
        </Modal>
    </Box>

  )
}

export default HomeV2