import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Modal,
  Paper,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router';
import Close from '@mui/icons-material/Close';

function QuickSearchToolbar() {
  return (
    <Box sx={{ p: 2, pb: 0, display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant="h6">Induction</Typography>
      <GridToolbarQuickFilter
        sx={{
          width: { xs: '60%', sm: '75%', md: '80%', lg: '90%' },
        }}
      />
    </Box>
  );
}

function Users() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState("#aabbcc");
  const sigCanvas = useRef({});
  const navigate = useNavigate();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [rows, setRows] = useState([]);
  const [user, setUser] = useState([]);
  const [image, setImage] = useState("");
  const [image_url, setImageURL] = useState("");
  const [category, setCategory] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);
  const [checkboxSelection] = useState(true);
  const params = useParams();

  const handlePopover = {
    open: (event) => setAnchorEl(event.currentTarget),
    close: () => setAnchorEl(null)
  };

  const handleChange = (event) => setCategory(event.target.value);

  const getUser = async () => {
    try {
      console.log(params.induction);
      const response = await fetch(
        `${localStorage.getItem("url")}/api/userInduction/${params.induction}}`
      );
      const jsonData = await response.json();
      console.log(jsonData)
      setUser(jsonData)
      // Transform data: split questions and answers into separate rows.
      const transformedRows = [];
      jsonData.forEach((item) => {
        const questions = item.questions ? item.questions.split(",") : [];
        const answers = item.answers ? item.answers.split(",") : [];
        // Loop through each question and create a row.
        questions.forEach((question, idx) => {
          transformedRows.push({
            id: generateRandom(), // Or any unique ID generator.
            question: question.trim(),
            answer: answers[idx] ? answers[idx].trim() : '',
            date_completed: item.date_completed,
            time_completed: item.time_completed,
          });
        });
      });
      setRows(transformedRows);
      // If you need to display a signature image from the first record:
      if (jsonData[0]?.signature) {
        setImage(URL.createObjectURL(jsonData[0].signature));
      }
      console.log(transformedRows);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getSignature = async () => {
    try {
      console.log(params.induction);
      const response = await fetch(`${localStorage.getItem("url")}/api/userSignature/${params.induction}}`);
      const jsonData = await response.json();
      console.log(jsonData);
      setImageURL(jsonData);
    } catch (err) {
      console.log(err.message);
    }
  };

  function generateRandom() {
    let retVal = "";
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (let i = 0; i < 8; i++) {
      retVal += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return retVal;
  }

  useEffect(() => {
    getUser();
    getSignature();
  }, []);

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    { field: 'question', headerName: 'Question', width: 430 },
    { field: 'answer', headerName: 'Answer', width: 250 },
    { field: 'date_completed', headerName: 'Date', width: 100 },
    { field: 'time_completed', headerName: 'Time', width: 100 },
  ];

  return (
    <Box sx={{ pl: '60px', width: '100%', display: 'flex', justifyContent: 'center', boxSizing: 'border-box' }}>
      <Box sx={{ width: '1200px' }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 5 }}>
          <Link underline="hover" color="inherit" href="/">Dashboard</Link>
          <Link underline="hover" color="inherit" href="/">User</Link>
        </Breadcrumbs>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', mb: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {user.length ? (
              <Box sx={{ display: 'flex' }}>
                <Typography variant="h4" sx={{ fontWeight: '600', pr: 2 }}>{user[0].name}</Typography>
              </Box>
            ) : (
              <Typography variant="h4" sx={{ fontWeight: '600' }}>loading..</Typography>
            )}
            <Typography variant="h4" sx={{ fontWeight: '600', px: 2 }}>/</Typography>
            {rows.length ? (
              <Box sx={{ display: 'flex' }}>
                <Typography variant="h4" sx={{ fontWeight: '600' }}>{user[0].induction_title}</Typography>
              </Box>
            ) : (
              <Typography variant="h4" sx={{ fontWeight: '600' }}>loading..</Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ width: '100%', mb: 2 }}>
          <Paper>
            <DataGrid
              sx={{ border: '0px' }}
              rows={rows.length ? rows : [{ id: "loading" }]}
              columns={columns}
              hideFooterPagination
              hideFooterSelectedRowCount
              selectionModel={selectionModel}
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: QuickSearchToolbar }}
              checkboxSelection={checkboxSelection}
              rowHeight={70}
              getRowId={() => generateRandom()}
            />
          </Paper>
        </Box>
        <Paper sx={{ width: '200px', p: 2 }}>
          <Typography sx={{ pb: 2 }}>Signature:</Typography>
          <img src={image_url} width="100%" height="100%" style={{ objectFit: 'cover', objectPosition: 'center' }} />
        </Paper>
      </Box>
    </Box>
  );
}

export default Users;
