import { Alert, Backdrop, Box, Button, Checkbox, CircularProgress, createTheme, InputAdornment, Paper, Snackbar, TextField, ThemeProvider, Typography } from '@mui/material'
import React, { useState, useEffect, Fragment, useRef } from "react";
import Logo from '../../Assets/Logo.png'
import md5 from "md5";
import sha1 from "sha1";
import { useNavigate, useParams } from 'react-router-dom'
function Login() {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#66BC44',
        contrastText: 'rgba(255,255,255,0.87)',
      },
      secondary: {
        main: '#f50057',
      },
    },
  });

    const url = localStorage.getItem("url");
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [re, setRe] = useState("HI");
    const [auth, setAuth] = useState("False");

    const navigate = useNavigate(); 

const groupLogIn = () => {
  navigate("group")
}

  const onSubmitForm = async (e) => {



    
    e.preventDefault();

    try {
      setOpen(!open)
      const hash_password = md5(sha1(password));
      const body = { email, hash_password };
      const response = await fetch(`${localStorage.getItem("url")}/api/login`, {
        method: "POST",
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         },
        body: JSON.stringify(body),
      });
      console.log(body);
      response.json().then((result) => {
        console.log(result)
        if (result == "failed") {
          localStorage.setItem("authenticated", "False");
          setOpen(false);
          setOpen2(true);
          document.getElementById('login').reset();
        } else  {
          localStorage.setItem("authenticated", "true");
          localStorage.setItem("name", result.name);
          localStorage.setItem("admin", result.admin);
          localStorage.setItem("category", result.category);
          localStorage.setItem("id", result.id);
          localStorage.setItem("orginization", result.organization);
          localStorage.setItem("access_level", result.access_level);
          localStorage.setItem("org",result.organisation_name)
          localStorage.setItem("org_id" , result.organisation)

          if(result.access_level === 1){
            window.location = `${localStorage.getItem("org")}/user`;
          }else if(result.access_level === 2){
            window.location = `/`;
          }else{
            window.location = "/infinite";
          }
        }
          
       });
        
      
    } catch (err) {
      console.log(err.message);
    }

    
    
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };


  return (
  <ThemeProvider theme={theme}>
          <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose}>
        <Alert variant="outlined" severity="error" sx={{ width: '100%' }} onClose={handleClose}>
          Email or password is incorrect.
        </Alert>
      </Snackbar>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
    </Backdrop>
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
            <form id="login" onSubmit={onSubmitForm} className="login-container"> 
        <Paper 
        sx={{
          width: {
            xs: 300,
            xl: 400,
          },
          alignItems: 'center', 
          justifyContent: 'center', 
          display: 'flex', 
          flexDirection: 'column', 
          boxSizing: 'border-box', 
          padding: {
            xs: 4,
            xl: 10,
          }, 
          mb: 15
          }}>
            <Box
            sx={{
              mb: {
                xs: 2,
              },
            }}>
              <img src={Logo} width='100%'/>
            </Box>

 

            <Box sx={{ width: '100%'}}>
                <Typography>Email</Typography>
            </Box>
            
            <TextField 
                sx={{ width: '100%' , pb: 2}} 
                size='small' 
                id="outlined" 
                variant="outlined" 
                placeholder='Email'
                onChange={(e) => setEmail(e.target.value)}
            />
            <Box sx={{ width: '100%'}}>
                <Typography>Password</Typography>
            </Box>
            <TextField 
                sx={{ width: '100%', pb: 2 }} 
                size='small' 
                id="myform"
                variant="outlined" 
                placeholder='Password'
                type="password"
                onChange={(e) => setPassword(e.target.value)}
            />
            
        
            <Button type="submit" onClick={onSubmitForm} id="login-button" variant='contained' sx={{ width: '100%', mb: 2 }}>login</Button>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                <Checkbox m={0}/>
                <Typography>Remember Me</Typography>
            </Box>
            <Box sx={{ opacity: '10%',backgroundColor: 'black', height: '1px', width: '100%', m: '10px 0'}}/>
            <Box color='text.secondary' sx={{ width: '100%'}}>
                <Typography onClick={groupLogIn} sx={{cursor: 'pointer'}}>Group Login</Typography>
            </Box>
        </Paper>
        </form>
    </Box>
    </ThemeProvider>
  
    
  )
}

export default Login
