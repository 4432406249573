import React, { useEffect, useState } from 'react'
import { Box, Typography, Card, CardActionArea, CardContent, CircularProgress, Grid2 } from '@mui/material'
import Thumbnail from '../../Assets/thumbnail.jpg'
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router-dom';

function UserDashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [inductions, setInductions] = useState([]);
  const [complete, setComplete] = useState([]);
  const [video, setVideos] = useState([]);

  const getUserInfo = async () =>{
    try{
      const response = await fetch(
          `${localStorage.getItem("url")}/api/client_user/${localStorage.getItem("id")}`
      );
      const jsonData = await response.json();
   
      setUser(jsonData);
      
     
      
  } catch (err){
      console.log(err.message);
  }
  
}; 

const getInductions = async () =>{
  try{
    const response = await fetch(
        `${localStorage.getItem("url")}/api/user_inductions/${localStorage.getItem("category")}`
    );
    const jsonData = await response.json();
 
    setInductions(jsonData);
    console.log(jsonData)

   
    
} catch (err){
    console.log(err.message);
}
}; 

const navInduction = async (id) => {
  console.log(id);
  navigate(`induction/${id}`)
 


}

const getInductionsComplete = async () =>{
  try{
    const response = await fetch(
        `${localStorage.getItem("url")}/api/user_inductions_complete/${localStorage.getItem("id")}`
    );
    const jsonData = await response.json();
 
    setComplete(jsonData);
    console.log(jsonData)

   
    
} catch (err){
    console.log(err.message);
}
}; 

const getVideos = async () =>{
  try{
    const response = await fetch(
        `${localStorage.getItem("url")}/api/videos/${localStorage.getItem("org_id")}`
    );
    const jsonData = await response.json();
 
    setVideos(jsonData);
    console.log(jsonData)

   
    
} catch (err){
    console.log(err.message);
}
}; 



  useEffect(() => {
    getUserInfo();
    getInductions();
    getInductionsComplete();
    getVideos();
    
  
   }, []);


  return (
    <Box sx={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
      <Box sx={{width: '100%', backgroundColor: '#66BC44', display: 'flex', justifyContent: 'center'}}>
        <Box sx={{             
          width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            }
            , height: {
              xs: 90,
              xl: 150,
            }, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
        {user.length 
            ?
              <Typography variant='h4' sx={{ mb: 3, color: 'white', fontSize: {
                xs: '24px',
                sm: '26px',
                md: '30px',
                lg: '32px',
                xl: '34px'
              }}}>Welcome {user[0].firstname}!</Typography>
            :
            <CircularProgress sx={{color: '#FFFFFF', mb: 3}}/>
        }
        </Box>
      </Box>
      <Box sx={{             
        width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            } , pt: 3, pb: 10}}>
          <Grid2 container spacing={2}>
            
          {inductions.length && video.length?
          inductions.map((i) => 
            
            {
              const completed = complete.find(o => o.induction_id === i.id)
              const thumbnail = video.find(o => o.video_id === i.video_id)
              console.log(thumbnail?.thumbnail_path)
              console.log(completed?.induction_id)
              
              if (completed?.induction_id == i.id ) {
                return(
                  <Grid2 size={{ sx: 12, sm: 6, md: 4 }} key={i.id} onClick={() => {navInduction(i.id)}}>
                      <Card>
                          <CardActionArea >
                            <Box sx={{ width: '100%',                            
                            height: {
                              xs: 250,
                              sm: 200,
                              md: 300,
                              lg: 300
                            },  display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <img src={thumbnail?.thumbnail_path} width='100%' height='100%' style={{ objectFit: 'cover', objectPosition: 'center' }}/>
                              
                            </Box>
                              <CardContent sx={{height: '100px'}}>
                              <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center'}}>
                                  {i.induction_title}
                              </Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1}}>
                                <CircleIcon sx={{ width: '10px', color: 'green', pr: 1}}/>
                                <Typography>Complete</Typography>
                              </Box>
                              </CardContent>
                          </CardActionArea>
                      </Card>
                    </Grid2>
                  )
              }
              else {
                return(
                  <Grid2 size={{ sx: 12, sm: 6, md: 4 }} key={i.id} onClick={() => {navInduction(i.id)}}>
                      <Card>
                          <CardActionArea >
                            <Box sx={{ width: '100%', 
                            height: {
                              sm: 150,
                              lg: 300
                            }, 
                            display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                              <img src={thumbnail?.thumbnail_path} width='100%' height='100%' style={{ objectFit: 'cover', objectPosition: 'center' }}/>
                            </Box>
                              <CardContent sx={{height: '100px'}}>
                              <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center'}}>
                                  {i.induction_title}
                              </Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2}}>
                                <CircleIcon sx={{ width: '10px', color: 'red', pr: 1}}/>
                                <Typography>Need to complete</Typography>
                              </Box>
                              </CardContent>
                          </CardActionArea>
                      </Card>
                    </Grid2>
                  )
              }
  
              })
              :
              <CircularProgress sx={{color: '#303030'}}/>
              }
        
          
              
             
          </Grid2>
        </Box>
    </Box>
  )
}

export default UserDashboard