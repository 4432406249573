import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import {
  Box, Typography, Button, Modal, TextField, Paper, Breadcrumbs, Link,
  IconButton, Autocomplete, Snackbar, Alert, Fab, Card, CardContent
} from '@mui/material';
import {
  DataGrid, GridToolbarQuickFilter
} from '@mui/x-data-grid';
import {
  Add, Close, Edit as EditIcon, Add as AddIcon, Circle as CircleIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import Moment from 'moment';

const QuickSearchToolbar = () => (
  <Box sx={{ p: 2, pb: 0, display: 'flex', justifyContent: 'space-between' }}>
    <GridToolbarQuickFilter sx={{ width: '100%' }} />
  </Box>
);

const ITEM_HEIGHT = 48, ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: { style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 } }
};

function InductionGroup() {
  const navigate = useNavigate();
  const [category, setNewCategory] = useState("");
  const [name, setNewName] = useState("");
  const [updatedname, setUpdatedName] = useState("");
  const [row, setRows] = useState([]);
  const [group, setGroup] = useState([]);
  const [categories, setCategories] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const org = localStorage.getItem('org');

  const styleModal = {
    position: 'absolute', top: '50%', left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 300, sm: 450 },
    boxSizing: 'border-box', bgcolor: 'white', borderRadius: '4px', boxShadow: 24, p: 4,
  };

  const handleClose = setter => () => setter(false);
  const handleOpen = setter => () => setter(true);

  const getUser = async id => {
    localStorage.setItem("group_id", id);
    navigate(`${id}`);
  };

  const columns = [
    { field: 'name', headerName: 'Name', width: 200, renderCell: cellValues => (
      <Box onClick={() => getUser(cellValues.id)} sx={{ cursor: 'pointer' }}>
        {cellValues.value}
      </Box>
    ) },
    { field: 'id', headerName: 'Group ID', width: 100 },
    { field: 'status', headerName: 'Completed', width: 110, renderCell: cellValues => (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircleIcon sx={{ width: '10px', color: cellValues.value === 1 ? 'green' : 'red', pr: 1 }} />
      </Box>
    ) },
    { field: 'category_name', headerName: 'Category', width: 220 },
    { field: 'date_completed', headerName: 'Date Completed', width: 200 },
    { field: 'time_completed', headerName: 'Time Completed', width: 200 },
    { field: 'actions', type: 'actions', width: 50, renderCell: params => (
      <IconButton onClick={() => { setGroup(params.row); setUpdatedName(params.row.name); setOpen2(true); }}>
        <EditIcon />
      </IconButton>
    ) },
  ];

  const getGroups = async () => {
    try {
      const response = await fetch(`${localStorage.getItem("url")}/api/groups/${localStorage.getItem("org_id")}`);
      const jsonData = await response.json();
      setRows(jsonData);
    } catch (err) { console.log(err.message); }
  };

  const addGroup = async e => {
    e.preventDefault();
    if (!category) return setOpen4(true);
    const org = localStorage.getItem("org_id");
    const body = { name, category, org };
    await fetch(`${localStorage.getItem("url")}/api/addGroup`, {
      method: "POST", headers: { "content-type": "application/json" },
      body: JSON.stringify(body)
    });
    window.location.reload();
  };

  const getCategories = async () => {
    try {
      const response = await fetch(`${localStorage.getItem("url")}/api/categories/${localStorage.getItem("org_id")}`);
      const jsonData = await response.json();
      setCategories(jsonData);
    } catch (err) { console.log(err.message); }
  };

  const updateGroup = async e => {
    e.preventDefault();
    if (updatedname.length > 0) {
      try {
        const body = { updatedname };
        await fetch(`${localStorage.getItem("url")}/api/updateGroup/${group.id}`, {
          method: "PUT", headers: { "Content-type": "application/json" },
          body: JSON.stringify(body)
        });
        window.location.reload();
      } catch (err) { console.error(err.message); }
    } else setOpen5(true);
  };

  function generateRandom() {
    let length = 8, charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", retVal = "";
    for (let i = 0, n = charset.length; i < length; i++) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  useEffect(() => { getGroups(); getCategories(); }, []);

  return (
    <Box sx={{ pl: { xs: '0px', lg: '60px' }, pt: { xs: 6, lg: 0 }, width: '100%', display: 'flex', justifyContent: 'center', boxSizing: 'border-box' }}>
      <Box sx={{ width: { xs: 300, sm: 500, md: 800, lg: 1000, xl: 1200 }, mb: 10 }}>
        <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose(setOpen4)} sx={{ ml: 7 }}>
          <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose(setOpen4)}>
            Please fill out all fields to create a Group.
          </Alert>
        </Snackbar>
        <Snackbar open={open5} autoHideDuration={6000} onClose={handleClose(setOpen5)} sx={{ ml: 7 }}>
          <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose(setOpen5)}>
            Please fill out all fields to update a Group.
          </Alert>
        </Snackbar>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', mb: 2 }}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 5 }}>
              <Link underline="hover" color="inherit" href={`/test/users`}>Dashboard</Link>
            </Breadcrumbs>
            <Typography variant="h4" sx={{ fontWeight: '600', fontSize: { xs: 25, md: 30 } }}>
              Induction Group
            </Typography>
          </Box>
          <Button onClick={handleOpen(setOpen1)} variant="contained" startIcon={<AddIcon />} sx={{ display: { xs: 'none', md: 'flex' } }}>
            Add
          </Button>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Fab size="small" color="primary" aria-label="add" onClick={handleOpen(setOpen1)} sx={{ zIndex: 1 }}>
              <Add />
            </Fab>
          </Box>
        </Box>
        <Paper sx={{ mb: 2 }}>
          <DataGrid
            sx={{ border: 0 }}
            rows={row || { id: "loading" }}
            columns={columns}
            getRowId={row => row.id}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: QuickSearchToolbar }}
          />
        </Paper>
      </Box>
      <Modal open={open1} onClose={handleClose(setOpen1)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleModal}>
          <Box sx={{ position: 'relative', boxSizing: 'border-box' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant='h6'>Group Details</Typography>
              <IconButton><Close onClick={handleClose(setOpen1)} /></IconButton>
            </Box>
            <Box mb={1}>
              <Typography>Group Name</Typography>
              <TextField fullWidth size='small' placeholder='Group Name' onChange={e => setNewName(e.target.value)} />
            </Box>
            <Box mb={2}>
              <Typography>Category</Typography>
              <Box sx={{ pb: 3 }}>
                <Autocomplete
                  size='small'
                  fullWidth
                  disableClearable
                  onChange={(a, b) => setNewCategory(b.category_id)}
                  options={categories}
                  getOptionLabel={option => option.category_name}
                  renderInput={params => (
                    <TextField {...params} placeholder='Category' InputProps={{ ...params.InputProps, type: 'search' }} />
                  )}
                />
              </Box>
            </Box>
            <Box textAlign="right">
              <Button variant='contained' onClick={addGroup}>Create</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={open2} onClose={handleClose(setOpen2)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={styleModal}>
          <Box sx={{ position: 'relative', boxSizing: 'border-box' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant='h6'>Group Details</Typography>
              <IconButton><Close onClick={handleClose(setOpen2)} /></IconButton>
            </Box>
            <Box mb={3}>
              <Typography>Group Name</Typography>
              <TextField fullWidth size='small' placeholder='Group Name' onChange={e => setUpdatedName(e.target.value)} defaultValue={group.name} />
            </Box>
            <Box textAlign="right">
              <Button variant='contained' onClick={updateGroup}>Update</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default InductionGroup;
