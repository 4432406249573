import React, { Fragment,useState, useEffect } from 'react';
import { Box, Container, Typography, Button, Modal, Select, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link, IconButton, Autocomplete, Snackbar, Alert, Fab, CircularProgress} from '@mui/material/'
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import { Add, Close, DeleteOutline } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import Moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function InductionGroup() {
    const navigate = useNavigate();
    const [category, setNewCategory] = useState("");
  const [row, setRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [group, setGroup] = useState([]);
  const [videos, setVideos] = useState([]);
  const [newUser, setGroupUser] = useState([]);
  const [newEquipment, setEquipmentAllocation] = useState([]);
  const [newItemNumber, setItemNumber] = useState([]);
  const [equipmentSelection, setEquipmentSelection] = useState([]);
  const [userToRemove, setUserToRemove] = useState([]);
  const [userEquipment, setUserEquipment] = useState([]);
  const [categories, setCategories] = useState([]);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen3 = () => setOpen3(true);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);
  const [checkboxSelection, setCheckboxSelection] = React.useState(true);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [val, setVal] = useState([{ equipment_id: "", text: "" }]); // Each row has an `equipment_id` and `text` field
  // This state holds which groups are expanded (by name)
  const [expandedGroups, setExpandedGroups] = useState([]);
  const handleAutocompleteChange = (selectedOption, index) => {
    setVal((prevValues) => {
        const updatedValues = [...prevValues];
        updatedValues[index] = { ...updatedValues[index], equipment_id: selectedOption.equipment_id };
        return updatedValues;
    });
};

// Handle TextField input change
const handleTextChange = (event, index) => {
    const { value } = event.target;
    setVal((prevValues) => {
        const updatedValues = [...prevValues];
        updatedValues[index] = { ...updatedValues[index], text: value };
        return updatedValues;
    });
};

// Add a new row
const addRow = () => {
    setVal([...val, { equipment_id: "", text: "" }]);
};

  const handleAdd=()=>{
    const abc=[...val,[]]
    setVal(abc)
}
const handleChange=(onChangeValue,i)=>{
  console.log(onChangeValue)
  const inputdata=[...val]
  inputdata[i]=onChangeValue.target.value;
  setVal(inputdata)
 }

 const handleChange2=(selectedOption, i)=>{
  console.log(selectedOption)
  const inputdata=[...val]
  inputdata[i]=selectedOption.target;
  console.log(inputdata)
  setVal(inputdata)
 }

 const handleDelete=(i)=>{
     const deletVal=[...val]
     deletVal.splice(i,1)
     setVal(deletVal)  
 }
  const updateGroupUser = (id) => {
    console.log(id)
    setGroupUser(newUser => [...newUser, id])
  }

      // Creating Induction Error Alert
      const [open4, setOpen4] = React.useState(false);
      const handleOpen4 = () => setOpen4(true);
      const handleClose4 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen4(false);
      };
            // Creating Induction Error Alert
            const [open5, setOpen5] = React.useState(false);
            const handleOpen5 = () => setOpen5(true);
            const handleClose5 = (event, reason) => {
              if (reason === 'clickaway') {
                return;
              }
          
              setOpen5(false);
            };
  const org = localStorage.getItem('org');
  const id = localStorage.getItem('induction_group_id');
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryDesc, setNewCategoryDesc] = useState("");
  const [allocationDate, setAllocationDate] = useState("");
  const [allocation, setAllocation] = useState("");
  const [user, setUser] = useState("");
  const [updateCategoryName, setUpdateCategoryName] = useState("");
  const [updateCategoryDesc, setUpdateCategoryDesc] = useState("");
  const [categoryinfo, setCategoryInfo] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const params = useParams();

  const styleModalVideo = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
  };

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 300,
        sm: 450,
      },
    boxSizing: 'border-box',
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const styleModal2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  
  
  const getAllocation = async () => {
    try{
        console.log(params.id)
        const response = await fetch(
            `${localStorage.getItem("url")}/api/allocation/${localStorage.getItem("org_id")}`
        );
        const jsonData = await response.json();
     
   
        setRows(jsonData);
        console.log(jsonData)

        
       
        
    } catch (err){
        console.log(err.message);
    }
    
   
  };

const getUser = async (id) => {
     

  console.log(id);
  navigate(`${id}`)
 
 

}


  const groupData = (rows, expandedGroups) => {
    const groups = {};
    rows.forEach(row => {
      if (!groups[row.name]) {
        groups[row.name] = [];
      }
      groups[row.name].push(row);
    });
    const groupedRows = [];
    Object.entries(groups).forEach(([groupName, groupRows]) => {
      // Add group header row with a custom id (using a prefix)
      groupedRows.push({
        id: `group-${groupName}`,
        isGroup: true,
        name: groupName,
        count: groupRows.length,
      });
      // If the group is expanded, add its rows
      if (expandedGroups.includes(groupName)) {
        groupedRows.push(...groupRows);
      }
    });
    return groupedRows;
  };

  
  // Toggle the expansion for a group (identified by the group's name)
  const toggleGroup = (groupName) => {
    setExpandedGroups(prev =>
      prev.includes(groupName)
        ? prev.filter(name => name !== groupName)
        : [...prev, groupName]
    );
  };

  // Create our new row array with group headers and children
  const groupedRows = groupData(row, expandedGroups);
  

const columns = [
  { 
    field: 'name', 
    headerName: 'Full Name', 
    width: 300,
    renderCell: (params) => {
      // Check if this is a group header row
      if (params.row.isGroup) {
        const isExpanded = expandedGroups.includes(params.row.name);
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%'
            }}
            onClick={() => toggleGroup(params.row.name)}
          >
            <IconButton size="small">
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            {params.row.name} ({params.row.count})
          </Box>
        );
      }
      // For non-group rows, just display the name
      return <Box>{params.value}</Box>;
    }
  },
  { 
    field: 'equipment_name', 
    headerName: 'Equipment', 
    width: 250,
    renderCell: (params) => {
      // Do not render equipment for group header rows
      if (params.row.isGroup) {
        return null;
      }
      return <Box>{params.value}</Box>;
    }
  },
  { 
    field: 'returned', 
    headerName: 'Status', 
    width: 70,
    renderCell: (params) => {
      // Do not render equipment for group header rows
      if (params.row.isGroup) {
        return null;
      }
      return<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <CircleIcon sx={{ width: '10px', color: params.value === 1 ? 'green' : 'red', pr: 1 }}/>
    </Box>;
    }
},
{ 
  field: 'cost', 
  headerName: 'Price', 
  width: 120,
  renderCell: (params) => {
    // Do not render equipment for group header rows
    if (params.row.isGroup) {
      return null;
    }
    return <Box>${params.value}</Box>;
  }
},
{ 
  field: 'issued_date', 
  headerName: 'Date Issued', 
  width: 200,
  renderCell: (params) => {
    // Do not render equipment for group header rows
    if (params.row.isGroup) {
      return null;
    }
    return <Box>{params.value}</Box>;
  }
},
{ 
  field: 'returned_date', 
  headerName: 'Date Returned', 
  width: 200,
  renderCell: (params) => {
    // Do not render equipment for group header rows
    if (params.row.isGroup) {
      return null;
    }
    return <Box>{params.value}</Box>;
  }
},
  // Add any additional columns here (be sure to check for isGroup if needed)
];



  /*const addAllocation = async (e) => {
    e.preventDefault();
    console.log("Submitting allocations:", val);

    for (const entry of val) {
        if (entry.equipment_id === "") {
            setOpen4(true); // Show error modal if equipment is empty
            return;
        }

        const current = new Date();
        const date = Moment(current).format("YYYY-MM-DD");
        const body = {
            newEquipment: entry.equipment_id, // Using selected equipment_id
            user,
            date,
            newItemNumber: entry.text, // Using text field input as newItemNumber
        };

        try {
            const response = await fetch(
                `${localStorage.getItem("url")}/api/addAllocation`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(body),
                }
            );

            if (!response.ok) {
                console.error("Failed to add allocation:", response.statusText);
            }
        } catch (error) {
            console.error("Error adding allocation:", error);
        }
    }

    setOpen1(false);
    window.location.reload();
};
*/

    const addAllocation = async (e) => {
      console.log(userEquipment)
      e.preventDefault();
  
      const equipments = [];
      const current = new Date();
      const date = Moment(current).format("YYYY-MM-DD");
      userEquipment.map((equipment) => {
        equipments.push(equipment.equipment_id)
        console.log(equipment)
      })
  
      if(users === ""){
          setOpen4(true);
        }
  
      else {
              const body = { user, equipments, date };
     
        const response = await fetch(
          `${localStorage.getItem("url")}/api/addAllocation`,
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
          }
        );
        setOpen1(false);
        window.location.reload();
      }
    };

  const getUsers = async () => {
    try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/users/${localStorage.getItem("org_id")}`
        );
        const jsonData = await response.json();
     
        setUsers(jsonData);
        console.log(jsonData)
   
       
        
    } catch (err){
        console.log(err.message);
    }
    

   
   
  };

  const getEquipment = async () => {
    try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/equipment/${localStorage.getItem("org_id")}`
        );
        const jsonData = await response.json();
     
        setEquipment(jsonData);
        console.log(jsonData)
   
       
        
    } catch (err){
        console.log(err.message);
    }
    

   
   
  };

  const deleteRow = (index) => {
    if (val.length === 1) return; // Prevent deleting the last row

    setVal((prevValues) => prevValues.filter((_, i) => i !== index));
  };

    const handleSelectionChange = (newSelection) => {
      setSelectedRowIds(newSelection); // Directly update state
      console.log("Selected IDs:", newSelection);
    };

  const changeStatus = async() => {
    console.log(selectedRowIds)
    console.log(selectedRowIds.length)
    const equipments = [];

    selectedRowIds.map((equipment) => {
      equipments.push(equipment)
      console.log(equipment)
    })

    if(selectedRowIds.length === 0){
        setOpen4(true);
      }

    else {
            const current = new Date();
            const date = Moment(current).format("YYYY-MM-DD");
            const body = { equipments, date };
            console.log(equipments)
      const response = await fetch(
        `${localStorage.getItem("url")}/api/equipmentStatus`,
        {
          method: "PUT",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      
        setOpen1(false);
        window.location.reload();
    }

   
  };

  const startInduction = () => {
    console.log(id)

    navigate(`${id}`)
  }

  useEffect(() => {
    getAllocation();
    getUsers();
    getEquipment();
  
   }, []);


  return (
    <Box     
    sx={{
        pl: {
            xs: '0px',
            lg: '60px',
        },
        pt: {
            xs: 6,
            lg: 0,
        },  
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        boxSizing: 'border-box'
    }}>
        <Box         
        sx={{ 
            width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            }, 
            mb: 10
            }}>
        <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
                Please select items to return.
              </Alert>
            </Snackbar>
            <Snackbar open={open5} autoHideDuration={6000} onClose={handleClose5} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose5}>
                Please fill out all fields to update a Category.
              </Alert>
            </Snackbar>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                        <Link underline="hover" color="inherit" href={`/test/users`}>
                            Dashboard
                        </Link>
                    </Breadcrumbs>
                    <Typography  variant="h4" sx={{ fontWeight: '600'}}>Equipment Allocation</Typography>
                </Box>
                <Box sx={{ display: 'flex'}}>

                <Button onClick={handleOpen1} variant="contained" startIcon={<AddIcon />}
                
                sx={{
                    display: {
                        xs: 'none',
                        md: 'flex',
                    },
                }}
                >
                    Add
                </Button>
                </Box>

                <Box sx={{
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                }}>
                    <Fab size="small" color="primary" aria-label="add" onClick={handleOpen1} sx={{zIndex: 1}}>
                        <Add />
                    </Fab>
                </Box>
            </Box>
            <Paper sx={{ boxSizing: 'border-box', mb: 2}}>
                <DataGrid
                  sx={{ border: '0px'}}
                  rows={groupedRows || {id: "loading"}}
                  columns={columns}
                  checkboxSelection
                  isRowSelectable={(params: GridRowParams) => params.row.returned < 1}
                  onRowSelectionModelChange={(newSelection) =>
                    handleSelectionChange(newSelection)
                  }
                  rowSelectionModel={selectedRowIds} // Ensures controlled selection
                  experimentalFeatures={{ newEditingApi: true }}
                  autoHeight
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{ toolbar: { showQuickFilter: true } }}
                />
              </Paper>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button variant='contained' onClick={changeStatus} sx={{height: '40px', mb: 10, display: { xs: 'none', sm: 'block' },}} >return items</Button>
                </Box>
        </Box>
        <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                  <Box sx={{ boxSizing: 'border-box', position: 'relative'}}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1}}>
                        <Typography variant='h6'>Add Allocation</Typography>
                        <IconButton>
                            <Close onClick={handleClose1}/>
                        </IconButton>
                    </Box>
                  <Box sx={{ mb: 2 }}>
                            <Typography>User</Typography>
                            <Box sx={{ pb: 3}}>
                            <Autocomplete
                                
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                onChange={(a, b) => setUser(b.id)}
                                options={users}
                                getOptionLabel={(users) => users.name }
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={params.id}
                                    placeholder='User'
                                          
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                
                                )}
                                
                                
                            />
                       </Box>
                       <Typography sx={{mb: 1}}>Equipment</Typography>
                       <Box>
                        <Autocomplete
                            size='small'
                            sx={{ width: '100%'}} 
                            disableClearable
                            onChange={(a, b) => setUserEquipment(b)}
                            options={equipment}
                            getOptionLabel={(option) => option.equipment_name || ""}
                            multiple
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                value={params.id}
                                placeholder='Equipment'    
                                InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                }}
                            />
                                
                                )}
                            />
                        {/*
                        {val.map((row, index)=>{
                                return(
                                <Box key={index} sx={{mb : 1, display: 'flex', justifyContent: 'space-between'}}>
                              <Autocomplete
                                
                                size='small'
                                sx={{ width: '40%'}} 
                                disableClearable
                                getOptionLabel={(option) => option.equipment_name || ""}
                                onChange={(event, value) => handleAutocompleteChange(value, index)}
                                options={equipment}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={params.id}
                                    placeholder='Equipment'
                                          
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                
                                )}
                                
                                
                            />
                            <TextField 
                                sx={{ width: '40%' }} 
                                size='small' 
                                variant="outlined" 
                                placeholder='Item Number'
                                value={row.text}
                                onChange={(event) => handleTextChange(event, index)}
                            />    
                                        <IconButton onClick={() => deleteRow(index)}>
                                            <Close />
                                        </IconButton>
                                </Box>
                                )
                            })} */}
                        </Box>
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={addAllocation}>ADD</Button>
                        </Box>
                    </Box>
                </Box>
                
            </Modal>
    </Box>
  )
}

export default InductionGroup
