import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography, Grid, Button, Modal, Select, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link } from '@mui/material/'
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Thumbnail from '../../Assets/thumbnail.jpg'
import AddIcon from '@mui/icons-material/Add';
import MovieIcon from '@mui/icons-material/Movie';
import ImageIcon from '@mui/icons-material/Image';
import TitleIcon from '@mui/icons-material/Title';
import DescriptionIcon from '@mui/icons-material/Description';

function Video() {
  const [category, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);

  const styleModalVideo = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
  };

  const styleModal = {
    position: 'absolute',
    borderRadius: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    height: '70vh',
    padding: 6,
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
    boxSizing: 'border-box'
  };

  return (
    <Box sx={{ width: '85%', display: 'flex', justifyContent: 'center', marginLeft: '250px', boxSizing: 'border-box'}}>
        <Box sx={{ width: '1200px'}}>
        <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                <Link underline="hover" color="inherit" href="/infinite">
                    Dashboard
                </Link>
            </Breadcrumbs>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                <Typography  variant="h4" sx={{ fontWeight: '600'}}>Onboarding</Typography>
                <Button href='/infinite/onboarding/add' variant="contained" startIcon={<AddIcon />}>
                    Add
                </Button>
            </Box>
            <Paper sx={{ p: 4, boxSizing: 'border-box', mb: 10}}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', borderRadius: '4px 4px 0 0'}}>
                    <Box align="left" sx={{mb: 3}}>
                        <Typography>Search</Typography>
                        <TextField 
                            sx={{ width: '800px'}} 
                            size='small' 
                            id="outlined" 
                            variant="outlined" 
                            placeholder='Search'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box align="left">
                        <Typography>Category</Typography>
                        <FormControl size='small' sx={{ width: '150px'}}>
                            <Select
                            value={category}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            <MenuItem value={1}>Name</MenuItem>
                            <MenuItem value={2}>Email</MenuItem>
                            <MenuItem value={3}>Phone</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box align="left">
                        <Typography>Active</Typography>
                        <FormControl size='small' sx={{ width: '150px'}}>
                            <Select
                            value={category}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            <MenuItem value={10}>Active</MenuItem>
                            <MenuItem value={20}>Disabbled</MenuItem>
                            <MenuItem value={30}>Waiting</MenuItem>
                            </Select>
                        </FormControl>
                        </Box>
                    </Box>
             <Grid container spacing={2} sx={{ pb: 4}}>
                <Grid xs={4}>
                    <Card>
                        <CardActionArea href='/infinite/onboarding/edit'>
                          <Box sx={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <img src={Thumbnail} width='100%' height='300px' style={{ objectFit: 'cover', objectPosition: 'center' }}/>
                          </Box>
                            
                            <CardContent>
                            <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center'}}>
                                Example Video
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                This is an example video that show cases the examples and how to example at example orchard.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card>
                        <CardActionArea>
                          <Box sx={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <img src={Thumbnail} width='100%' height='300px' style={{ objectFit: 'cover', objectPosition: 'center' }}/>
                          </Box>
                            
                            <CardContent>
                            <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center'}}>
                                Example Video
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                This is an example video that show cases the examples and how to example at example orchard.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card>
                        <CardActionArea>
                          <Box sx={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <img src={Thumbnail} width='100%' height='300px' style={{ objectFit: 'cover', objectPosition: 'center' }}/>
                          </Box>
                            
                            <CardContent>
                            <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center'}}>
                                Example Video
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                This is an example video that show cases the examples and how to example at example orchard.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4}>
                    <Card>
                        <CardActionArea>
                          <Box sx={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <img src={Thumbnail} width='100%' height='300px' style={{ objectFit: 'cover', objectPosition: 'center' }}/>
                          </Box>
                            
                            <CardContent>
                            <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center'}}>
                                Example Video
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                This is an example video that show cases the examples and how to example at example orchard.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            </Paper>
        </Box>
        <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                  <Box sx={{ boxSizing: 'border-box', }}>
                        <Typography variant='h4' sx={{ pb: 3}}>Create Video</Typography>
                        <Box align="left">
                      <Typography>Video Title</Typography>
                      <TextField 
                          sx={{ width: '100%', pb: 2}} 
                          size='small' 
                          id="outlined" 
                          variant="outlined" 
                          placeholder='Video Title'
                          InputProps={{
                              startAdornment: (
                              <InputAdornment position="start">
                                  <TitleIcon />
                              </InputAdornment>
                              ),
                          }}
                      />
                    </Box>
                    <Box align="left">
                      <Typography>Video Description</Typography>
                      <TextField 
                          sx={{ width: '100%', pb: 2}} 
                          size='small' 
                          id="outlined" 
                          variant="outlined"
                          multiline
                          placeholder='Short Video Description'
                          InputProps={{
                              startAdornment: (
                              <InputAdornment position="start">
                                  <DescriptionIcon />
                              </InputAdornment>
                              ),
                          }}
                      />
                    </Box>
                    <Box align="left">
                      <Typography>Video URL</Typography>
                      <TextField 
                          sx={{ width: '100%', pb: 2}} 
                          size='small' 
                          id="outlined" 
                          variant="outlined" 
                          placeholder='Video URL'
                          InputProps={{
                              startAdornment: (
                              <InputAdornment position="start">
                                  <MovieIcon />
                              </InputAdornment>
                              ),
                          }}
                      />
                    </Box>
                    <Box align="left">
                      <Typography>Thumbnail File</Typography>
                      <TextField 
                          sx={{ width: '100%'}} 
                          size='small' 
                          id="outlined"
                          type="file"
                          variant="outlined" 
                          placeholder='Search'
                          InputProps={{
                              startAdornment: (
                              <InputAdornment position="start">
                                  <ImageIcon />
                              </InputAdornment>
                              ),
                          }}
                      />
                    </Box>
                    <Box sx={{position: 'absolute', bottom: '0', paddingBottom: 6}}>
                      <Button variant='contained'>Create</Button>
                    </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModalVideo}>
                  <Box sx={{ position: 'relative', paddingTop: '56.25%'}}>
                    <ReactPlayer
                      className='react-player'
                      url='https://vimeo.com/780541642'
                      width='100%'
                      height='100%'
                      controls='true'
                      style={{ position: 'absolute', top: '0', left: '0' }}
                    />
                  </Box>

                </Box>
            </Modal>
    </Box>
  )
}

export default Video